import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
import { api, requestHandler } from "../../helpers/axios";

class Service extends CrudService {
  constructor(url) {
    super(url);
    this.delete = this.delete.bind(this);
  }
  
  async delete(id) {
    let url = this.url;
    url += "/" + id;
    return requestHandler((await api()).delete(url));
  }
}

export default new Service(server.API_SHARED_LINKS);
