import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
// import { api, requestHandler } from "../../helpers/axios";

class Service extends CrudService {
  constructor(url) {
    super(url);
    this.setUrl = this.setUrl.bind(this);
  }
  setUrl() {
    this.url = server.apiGroups.apply(null, arguments);
  }
}

export default new Service();
