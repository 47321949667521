import * as service from "./service";
import { notification } from "antd";

export default {
  state: {
    loading: false,
    medias: {
      docs: []
    },
    media: {},
    mediaShared: {
      media: {},
      user: {}
    },
    comments: [],
    groupMedia:[],
    notes:[],
    s3Url:'',
    eventComments: [],
    mediaAnnotations: [],
    activitiesList: [],
    commandList: [],
    activityUsers: []

  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onGetMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        media: data
      };
    },
    onShowSharedMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        mediaShared: data
      };
    },
    onGetNotesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        notes: data
      };
    },
    onGetS3UrlSuccess(state, data) {
      return {
        ...state,
        loading: false,
        s3Url: data.urls
      };
    },

    onReplaceThumbnailSuccess(state, data) {
      notification.success({
        message: data.message,
        description: data.message,

      });
      return {
        ...state,
        loading: false,
      };
    },

    onListCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        comments: data
      };
    },

    onListEventCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        eventComments: data
      };
    },
    
    onGetMediaAnnotationsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        mediaAnnotations: data
      };
    },

    onListCommandsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        commandList: data
      };
    },
    onListActiveUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        activityUsers: data
      };
    },
    
    onGetActivitiesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        activitiesList: data
      };
    },

    onListMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        medias: data
      };
    },

    afterSuccess(state){

    }
  },
  effects: {
    async createShareLink(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.createShareLink(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async showSharedMedia(payload) {
      try {
        this.onRequest();
        let res = await service.showSharedMedia(payload);
        if (res === "Incorrect Password") {
          return notification.error({
            message: "Incorrect Password",
            description: "Incorrect Password"
          });
        }
        this.onShowSharedMediaSuccess(res);

        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listMedia(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.listMedia(payload);
        this.onListMediaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getMedia(payload) {
      try {
        this.onRequest();
        let res = await service.getMedia(payload);
        this.onGetMediaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateMedia(payload) {
      try {
        this.onRequest();
        let res = await service.updateMedia(payload);
        this.onGetMediaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listComments(payload) {
      try {
        this.onRequest();
        let res = await service.listComments(payload);
        this.onListCommentsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },


    async getTwilioToken(payload) {
      try {
        this.onRequest();
        let res = await service.getTwilioToken(payload);
        // this.onGetTwilioTokenSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },


    async listEventComments(eventId) {
      try {
        this.onRequest();
        let res = await service.listEventComments(eventId);
        this.onListEventCommentsSuccess(res)
        // this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    


    async mediaSocialShare(payload) {
      try {
        this.onRequest();
        let res = await service.mediaSocialShare(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async removeMedia(data,payload){
      try {
        this.onRequest();
        const {event_id,media_id} = data;
        let res = await service.removeMedia(event_id,media_id);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateMediaName(data,payload){
      try {
        this.onRequest();
        let res = await service.updateMediaName(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createNotes(data,payload){
      try {
        this.onRequest();
        let res = await service.createNotes(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listNotes(eventId){
      try {
        this.onRequest();
        let res = await service.listNotes(eventId);
        this.onGetNotesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listActivities(data){
      try {
        this.onRequest();
        let res = await service.listActivities(data);
        this.onGetActivitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listCommands(eventId){
      try {
        this.onRequest();
        let res = await service.listCommands(eventId);
        this.onListCommandsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async listActiveUsers(eventId){
      try {
        this.onRequest();
        let res = await service.listActiveUsers(eventId);
        this.onListActiveUsersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getS3Url(){
      try {
        this.onRequest();
        let res = await service.getS3Url();
        this.onGetS3UrlSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async replaceThumbnail(thumbnailObj){
      try {
        this.onRequest();
        let res = await service.replaceThumbnail(thumbnailObj);
        this.onReplaceThumbnailSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createAnnotations(details){
      try {
        this.onRequest();
        let res = await service.createAnnotations(details);
        this.onSuccess(res);
        await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getMediaAnnotations(details){
      try {
        this.onRequest();
        let res = await service.getMediaAnnotations(details);
        this.onGetMediaAnnotationsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateAnnotations(details){
      try {
        this.onRequest();
        let res = await service.updateAnnotations(details);
        this.onSuccess(res);
        await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async deleteNotes(details){
      try {
        this.onRequest();
        let res = await service.deleteNote(details.id);
        this.onSuccess(res);
        await this.listNotes(details.event_id)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async onDeleteAnnotation(details){
      try {
        this.onRequest();
        let res = await service.onDeleteAnnotation(details);
        this.onSuccess(res);
        await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async updateGroupStatus(details) {
      try {
        this.onRequest();
        let res = await service.updateGroupStatus(details);
        this.onSuccess(res);
        // await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async deleteGroupComment(comment) {
      try {
        this.onRequest();
        let res = await service.deleteGroupComment(comment);
        this.onSuccess(res);
        // await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async deleteMediaComment(comment) {
      try {
        this.onRequest();
        let res = await service.deleteMediaComment(comment);
        this.onSuccess(res);
        // await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async deleteEventComment(comment) {
      try {
        this.onRequest();
        let res = await service.deleteEventComment(comment);
        this.onSuccess(res);
        // await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateMedias(payload) {
      try {
        this.onRequest();
        let res = await service.updateMedias(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async transferOwnerShip(payload) {
      try {
        this.onRequest();
        let res = await service.transferOwnerShip(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
  }
};
