import React, { Component } from "react";
import { Provider } from "react-redux";
import { store, history } from "./store";
import Routes from "./router";
import { LocaleProvider } from "antd";
import { DragDropContext } from "react-dnd";
// import HTML5Backend from "react-dnd-html5-backend";
// import TouchBackend from 'react-dnd-touch-backend';
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import TimeOut from "./Components/TimeOut";
import { logout } from "./Pages/Auth/service";

import "./assets/antd.css";
class App extends Component {

state={visible: false}

  async logoutUser () {
    await logout()
    this.setState({visible: true})
  }
  componentDidMount() {
    if(window.location.pathname === "/serviceDown") {
      history.push("/home")
    }
     // Include the Crisp code here, without the <script></script> tags
     window.$crisp = [];
    //  window.$crisp = [["do", "chat:open"],["set", "message:text", ["Hi! I'd like to get help."]],["set", "user:email", ["test@test.com"]],["set", "user:nickname", ["James Bond"]]];
     window.CRISP_WEBSITE_ID = "16d1cdcc-b75a-4879-b45f-d7610f18ea00";
 
     (function() {
       var d = document;
       var s = d.createElement("script");
       s.src = "https://client.crisp.chat/l.js";
       s.async = 1;
       d.getElementsByTagName("head")[0].appendChild(s);
     })();
    };
  

  render() {

    return (
      <div>
        <TimeOut logoutUser = {this.logoutUser.bind(this)}/>
        {/* <DragDropContextProvider backend={HTML5Backend}> */}
          <LocaleProvider>
            <Provider store={store}>
              <Routes history={history} />
            </Provider>
          </LocaleProvider>
        {/* </DragDropContextProvider> */}
      </div>
    );
  }
}
export default DragDropContext(MultiBackend(HTML5toTouch))(App);
