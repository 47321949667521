import * as service from "./service";

export default {
  state: {
    loading: false,
    undownloadedMedia: [],
    unrepliedComments:[]

  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onListMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        undownloadedMedia: data
      };
    },

    onListCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        unrepliedComments: data
      };
    },

  },
  effects: {
    async listUndownloadedMedias(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.listUndownloadedMedias();
        this.onListMediaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async listUnreadComments(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.listUnreadComments();
        this.onListCommentsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    }

  }
}