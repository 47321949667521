import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import {objToUrlParams} from '../../helpers/utils'

export async function listDepartments(payload) {
  let url
  if(payload && payload.name)
    url = `${server.API_DEPARTMENTS}?name=${payload.name}`
  else
    url = `${server.API_DEPARTMENTS}`
    
  return (await api())
    .get(url)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function createDepartment(payload) {
  return (await api())
    .post(server.API_DEPARTMENTS, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function deleteDepartment(payload) {
  return (await api()) 
    .delete(`${server.API_DEPARTMENTS}/${payload.departmentId}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function getDepartment(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function addDepartmentUsers(payload) {
  return (await api())
    .post(`${server.API_DEPARTMENTS}/${payload.departmentId}/users`, {...payload})
    .then(res => res.data)
    .catch(catchHandler)
}

export async function deleteDepartmentUsers(payload) {
  return (await api())
    .delete(`${server.API_DEPARTMENTS}/${payload.departmentId}/users/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function inviteUser(payload) {
  return (await api())
    .post(`${server.API_DEPARTMENTS}/${payload.departmentId}/users/invite`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function listUsers(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.departmentId}/users`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function addMedia(payload) {
  return (await api())
    .post(`${server.API_DEPARTMENTS}/${payload.departmentId}/media`, payload.values)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function listMedia(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.departmentId}/media?${objToUrlParams({limit: payload.limit, offset: payload.offset})}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function getMedia(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.departmentId}/media/${payload.mediaId}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function deleteMedia(payload) {
  return (await api()) 
    .delete(`${server.API_DEPARTMENTS}/${payload.departmentId}/media`, {params: {department_id: payload.departmentId, media_id: payload.mediaId}})
    .then(res => res.data)
    .catch(catchHandler)
}

export async function downloadMedia(payload) {
  return (await api())
    .post(`${server.API_DEPARTMENTS}/${payload.eventId}/media-download`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function listComments(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.departmentId}/comments`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function listMediaComments(payload) {
  return (await api())
    .get(`${server.API_DEPARTMENTS}/${payload.departmentId}/media/${payload.mediaId}/comments`)
    .then(res => res.data)
    .catch(catchHandler)
}



export const deleteDepartmentComment = async (comment) => {
  return (await api())
    .delete(server.apiDepartmentComments(comment.departmentId) + '/' + comment.commentId)
    .then(res => res.data)
    .catch(catchHandler);
};