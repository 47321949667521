import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
// import { objToUrlParams } from "../../helpers/utils";

export async function listUndownloadedMedias() {
  return (await api())
    .get(server.API_UNDOWNLOADED)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function listUnreadComments() {
    return (await api())
      .get(server.API_UNREAD_COMMENTS)
      .then(res => res.data)
      .catch(catchHandler);
  }