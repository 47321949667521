import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/messaging";

import server from "./server";

import notificationTokenService from "../Containers/NotificationTokens/service";
var config = {
  apiKey: "AIzaSyD9y4NQdv2DTJ_zNcTsKAFGOO8tJfgaMFY",
  authDomain: "binumi-pro.firebaseapp.com",
  databaseURL: "https://binumi-pro.firebaseio.com",
  projectId: "binumi-pro",
  storageBucket: "binumi-pro.appspot.com",
  messagingSenderId: "285499129974"
};

export function init() {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);

    firebase.auth().onAuthStateChanged(user => {
      if (user && server.AllowPushNotification) {
        initializePush();
      }
    }, console.error);
  }
}

export function firebaseMessaging() {
  if (!global.messaging) {
    if (firebase.messaging.isSupported()) {
      global.messaging = firebase.messaging();
      global.messaging.usePublicVapidKey(
        "BItOQoZaQ1bIZZxg78c9_3MJdlEiGeK2tHqaOCi5gLpjM3j8MHyI85fxfeQFa5vFOgwzUf6mu4JbIfMcLbiaUuk"
      );
    }
    
  }
  return global.messaging;
}

export function initializePush() {
  const messaging = firebaseMessaging();
  if (!messaging) return;
  messaging
    .requestPermission()
    .then(() => {
      // console.log("Have Permission");
      return messaging.getToken();
    })
    .then(token => {
      // console.log("FCM Token:", token);
      global.fcm_token = token;
      //you probably want to send your new found FCM token to the
      //application server so that they can send any push
      //notification to you.
      return notificationTokenService
        .create({ token, platform: "web" })
        .catch(error => {
          return; //console.log(error);
        });
    })
    .catch(error => {
      if (error.code === "messaging/permission-blocked") {
        // console.log("Please Unblock Notification Request  Manually");
      } else {
        // console.log("Error Occurred", error);
      }
    });
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export default firebase;
