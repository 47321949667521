import React from "react";

import Loader from "../Components/Loader";

export default function asyncComponent(importComponent) {
  class AsyncFunc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }
    componentWillMount() {}
    componentWillUnmount() {
      this.mounted = false;
    }
    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();

      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />
        });
      }
    }

    render() {
      const Component = this.state.component || <Loader />;
      return Component;
    }
  }
  return AsyncFunc;
}
