import * as service from "./service";

import { notification } from "antd";

export default {
  state: {
    loading: false,
    events: [],
    event: {},
    admin: {},
    allUsers: [],
    eventTransactions: [],
    allEventUsers: []
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        events: data
      };
    },
    clearEvents(state, data) {
      return {
        ...state,
        loading: true,
        events: [],
        eventCount: 0
      };
    },
    onListPaginatedEventsSuccess(state, data) {
      let oldEvent = state.events || []
      let newEvent = oldEvent.concat(data.rows || [])
      return {
        ...state,
        loading: false,
        events: newEvent,
        eventCount: data.count || 0
      };
    },
    
    onGetEventSuccess(state, data) {
      return {
        ...state,
        loading: false,
        event: data.event,
        admin: data.admin,
        allEventUsers: data.user
      };
    },
    onGetEventTransactionSuccess(state, data) {
      return {
        ...state,
        loading: false,
        eventTransactions: data,
      };
    },
    onGetUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allUsers: data
      };
    },
    
    onDeleteEventSuccess(state, data) {
      let oldEvent = state.events || []
      let someArray = oldEvent.filter(person => person.id !== data);
      return {
        ...state,
        loading: false,
        events: someArray,
        eventCount: state.eventCount-1
      };
    },
    onCreateEventSuccess(state, data) {
      let oldEvent = state.events || []
      let newEvent = oldEvent.unshift(data)
      
      return {
        ...state,
        loading: false,
        events: oldEvent,
        eventCount: state.eventCount+1
      };
    },

    onMediaDownloadSuccess(state, data) {
      // if (data.message === "Old") {
      //   notification.error({
      //     message: "Only new videos can be downloaded",
      //     description: "Only new videos can be downloaded"
      //   });
      // } else
      if (data.data) {
        notification.error({
          message: data.data,
          description: data.data
        });
      } else {
        notification.success({
          message:
            "You will receive a notification when file is ready to download",
          description:
            "You will receive a notification when file is ready to download"
        });
      }
      return {
        ...state,
        loading: false
      };
    }
  },
  effects: {

    async eventsStatus(data) {
      try {
        this.onRequest();
        let res = await service.eventsStatus(data);
        this.onSuccess(res);
        let events = {
          inOperations: true
        }
        await this.listEvents(events)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },


    async listEvents(payload, rootState) {
      try {
        this.onRequest();
        if (payload) {
          payload.sort_field = "id";
          payload.sort_order = "DESC";
          if (payload.inOperations === true) {
            payload.sort_field = "updatedAt";
            payload.inOperations = true;
          }
        } else {
          payload = { sort_field: "id", sort_order: "DESC" };
        }
        let res = await service.listEvents(payload);
        this.onListSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async listPaginatedEvents(payload, rootState) {
      try {
        this.onRequest();
        if (payload) {
          payload.sort_field = "id";
          payload.sort_order = "DESC";
          if (payload.inOperations === true) {
            payload.sort_field = "updatedAt";
            payload.inOperations = true;
          }
        } else {
          payload = { sort_field: "id", sort_order: "DESC" };
        }
        let res = await service.listEvents(payload);
        this.onListPaginatedEventsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getEvent(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.getEvent(payload);
        this.onGetEventSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateEvent(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.updateEvent(payload);
        await this.getEvent(payload.id)
        await this.listEvents()
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async deleteEvent(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.deleteEvent(payload);
        this.onDeleteEventSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createEvent(payload) {
      try {
        this.onRequest();
        let res = await service.createEvent(payload);
        await this.onCreateEventSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async mediaDownload(data, payload) {
      try {
        this.onRequest();
        let res = await service.mediaDownload(data);
        this.onMediaDownloadSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async mediaStatusUpdate(data, payload) {
      try {
        this.onRequest();
        let res = await service.mediaStatusUpdate(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },


    async getUsers({ limit, offset, ...rest }) {
      try {
        this.onRequest();
        let res = await service.getUsers({ limit, offset, ...rest });
        this.onGetUsersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getMediaForDownload(payload) {
      try {
        this.onRequest();
        let res = await service.getMediaForDownload(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createEventTransaction(payload) {
      try {
        this.onRequest();
        let res = await service.createEventTransaction(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getEventTransaction(payload) {
      try {
        this.onRequest();
        let res = await service.getEventTransaction(payload);
        this.onGetEventTransactionSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
  }
};
