import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { NavLink } from "react-router-dom";
import { Layout, Menu, Modal, Dropdown, Avatar, Icon } from "antd";
import Notifications from "../Notifications";
import { isSuperAdmin } from "../../helpers/auth";
import sideLogo from "../../assets/images/totemaction-logo.png";

const { Header } = Layout;
const confirm = Modal.confirm;

const mapDispatchToProps = ({ authModel, operationModel }) => {
  return {
    ...authModel,
    ...operationModel
  };
};

const mapStateToProps = ({ authModel, operationModel }) => {
  return {
    ...authModel,
    ...operationModel,
  };
};

class BaseLayout extends Component {
  state = {
    collapsed: false,
    className: 'sidemenu-enabled',
    getFirst: true
  }
  async componentDidMount() {
    await this.props.me();
  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.id) {
      if (this.state.getFirst === true) {
        // let data = {
        //   id: nextProps.user.id
        // }
        await this.setState({ getFirst: false })
        // await this.props.getUsersDetails(data)
      }
    }
    if (nextProps.settings && nextProps.settings.showSidebar) {
      document.body.classList.add('sidemenu-enabled', 'home-body-wrapper');
      // document.body.classList.add('home-body-wrapper');
      document.body.classList.remove('operations-sidemenu-enabled');
    } else {
      document.body.classList.remove('sidemenu-enabled', 'home-body-wrapper');
    }
    if (window.innerWidth < 1140) {
      document.body.classList.remove('sidemenu-enabled');
    }
    if ((window.location.pathname).includes("videos")) {
      document.body.classList.remove('sidemenu-enabled');
    }
  }

  async confirmLogout() {
    return confirm({
      title: "Are you sure you want to logout?",
      content: "You will be logged out.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this.logout();
      },
      onCancel() {
      }
    });
  }

  async logout() {
    localStorage.removeItem(this.props.user.id);
    await this.props.logout();
    window.location.reload();
  }

  toggleMenu() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    document.body.classList.toggle('sidemenu-enabled');
  }

  render() {
    const { children, user } = this.props;
    return (
      <Layout
        style={{
          padding: "auto",
          minHeight: window.innerHeight
        }}
        className="app-main-layout"
      >
        {user &&
          <Header className="main-header" style={{ zIndex: 25, width: "100%" }}>
            <Menu
              theme="light"
              mode="horizontal"
              className="menu-block-wrap"
            // mode="inline"
            // inlineCollapsed={this.state.collapsed}
            >
              {/* {settings.showSidebar && */}
              <Menu.Item key="11" className="menu-btn-item logo-block-wrapper">
                <div>
                  <button className="btn menu-btn" onClick={this.toggleMenu.bind(this)}>
                    {this.state.collapsed ? <Icon type="menu-unfold" /> : <Icon type="menu-fold" />}
                  </button>
                  <NavLink to={"/"} className="logo-block">
                    <figure>
                      <img className="logo-img" src={sideLogo} alt="logo"></img>
                    </figure>
                  </NavLink>
                </div>
              </Menu.Item>
              {/* } */}
              {/* {(settings.title === "Profile Settings" || settings.title === "Shared Link" || settings.title === "Operations" || settings.title === "Contact Us") &&
              <Menu.Item key="9" className="main-logo">
                <NavLink to={"/"}><img src={logo} alt="" /></NavLink>
              </Menu.Item>
            } */}
              {/* <Menu.Item key="1">
            //   <NavLink to={"/events"}>Home</NavLink>
            // </Menu.Item> */}

              {/* {isSuperAdmin(user) && (
              <Menu.Item key="2">
                <NavLink to={"/registration-requests"}>Registration Requests</NavLink>
              </Menu.Item>
            )} */}


              {isSuperAdmin(user) && (
                <Menu.Item key="3" className="nav-operations">
                  <NavLink to={"/operations"}>Operations</NavLink>
                </Menu.Item>
              )}
              {/* {isSuperAdmin(user) && (
              <Menu.Item key="12">
                <NavLink to={"/admin-board"}>Admin Board</NavLink>
              </Menu.Item>
            )} */}

              <Menu.Item key="7" className="nav-shared-links">
                <NavLink to={"/shared-links"}>Shared Links</NavLink>
              </Menu.Item>
              {/* <Menu.Item key="3" onClick={this.confirmLogout.bind(this)}>
              Logout
            </Menu.Item> */}
              {/*
            <Menu.Item key="4" >
              <NavLink to={"/FAQ"}>FAQ</NavLink>
            </Menu.Item>
            */}
              <Menu.Item key="5" className="nav-support">
                <NavLink to={"/Support"}>Support</NavLink>
              </Menu.Item>

              <Menu.Item key="10" style={{ display: "inline-block" }} className="header-my-profile">
                <UserProfile logout={this.confirmLogout.bind(this)} user={user} />
              </Menu.Item>
              <Menu.Item key="8" style={{ display: "inline-block" }} className="header-notification">
                <Notifications style={{ fontSize: "22px" }} />
              </Menu.Item>
              {/* <Menu.Item key="6"  style={{ display: "inline-block" }} className="header-notification">
              <NavLink to={"/my-profile"}>
                <div className="credit-header-contain">
                  <p className="credit-title">Credits</p>
                  <p className="credit-amount">{user && user.credits}</p>
                </div>
            </NavLink>
            </Menu.Item> */}

            </Menu>
          </Header>
        }
        <Layout className="main-content-spacer">
          {children}
        </Layout>
       
      </Layout>
    );
  }
}

function UserProfile({ user, logout }) {
  return (

    <Dropdown
      overlay={
        <Menu>
          <Menu.Item >
            <NavLink to={"/my-profile"}>
              <b>{user && user.first_name} {user && user.last_name} </b>
            </NavLink>
          </Menu.Item>
          <Menu.Divider></Menu.Divider>
          {/* <Menu.Item>
              <NavLink to={"/my-profile"}>My Profile</NavLink>
            </Menu.Item> */}
            <Menu.Item onClick={logout}>
              <b> Logout</b>
            </Menu.Item>

        </Menu>
      }
      trigger={["click"]}
    >
      <div className="header-user-dp"><Avatar src={user && user.profile_pic} icon="user" /></div>
    </Dropdown>
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BaseLayout);
