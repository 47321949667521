import * as service from "./service";

export default {
  state: {
    loading: false,
    events: [],
    event: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onSendContactData(state, data) {      
      return {
        ...state,
        loading: false,
        data: data
      };
    }
  },
  effects: {
    async sendContactData(payload) {
      try {
        this.onRequest();
        let res = await service.sendContactData(payload);
        this.onSendContactData(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    }
  }
};
