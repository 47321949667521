import * as service from "./service";
import { notification } from "antd";

export default {
  state: {
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      }
    },
    onError(state) {
      return {
        ...state,
        loading: false
      }
    },
    onSuccess(state) {
      return {
        ...state,
        loading: false
      }
    },
    onListYourVideosSuccess(state, data) {
      return {
        ...state,
        loading: false,
        yourVideos: data
      }
    },
    onListUploadedVideosSuccess(state, data) {
      return {
        ...state,
        loading: false,
        yourUploadedVideos: data
      }
    },
    onAddVideoSuccess(state, data) {
      let message = `${data.accepted && data.accepted.length ? `${data.accepted.length} medias added${data.rejected && data.rejected.length ? '' : ' to your videos'}. ` : ''} ${data.rejected && data.rejected.length ? `${data.rejected.length} medias already exists in your videos` : ''}`
      if (data.accepted && data.accepted.length)
        notification.success({
          message: 'Success',
          description: message
        })
      else
        notification.info({
          message: 'Could not add',
          description: message
        })
      return {
        ...state,
        loading: false,
      }
    },
    onDeletVideoSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onGetVideoSuccess(state, data) {
      return {
        ...state,
        loading: false,
        yourVideo: data
      }
    },
    onSetMediaType(state, type) {
      return {
        ...state,
        type: type
      }
    }
  },
  effects: {
    async listYourVideos(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listYourVideos(payload)
        this.onListYourVideosSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async listUploadedVideos(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listUploadedVideos(payload)
        this.onListUploadedVideosSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async addToYourVideos(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.addVideo(payload)
        this.onAddVideoSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async deleteYourVideo(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.deleteVideo(payload)
        this.onDeletVideoSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async getYourVideo(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.getVideo(payload)
        this.onGetVideoSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async downloadYourVideo(type, rootState, payload) {
      try {
        this.onRequest()
        let res = await service.downloadVideo(payload, type)
        this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async setMediaType(type) {
      this.onSetMediaType(type)
      return 
    }
  }
}