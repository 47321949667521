/**
 * Create a redux rematch model.
 * @param {Object[]} params - The model params.
 * @param {string} params[].name - The name of data storage property and its value will be inititial state.
 * @param {string} params[].methodName - The name of the method and it value must be a method
 */

export default function(params) {
  params = Array.isArray(params) ? params : [params];
  const model = {
    state: {
      loading: false
    },
    reducers: {},
    effects: {}
  };
  params.forEach(param => {
    addMethods(model, param);
  });
  return model;
}

function addMethods(model, param) {
  const { name, initialState, service, methodName } = processParams(param);
  model.state[name] = initialState;
  model.reducers = {
    ...model.reducers,
    ...createReducer(name, methodName, initialState)
  };
  model.effects[methodName] = createEffect(methodName, service);
  return model;
}

function processParams(params = {}) {
  const [name, methodName] = Object.keys(params);
  return {
    name,
    initialState: params[name],
    methodName,
    service: params[methodName]
  };
}

function onRequest(name, initialState) {
  return state => {
    return {
      ...state,
      // [name]: initialState,
      loading: true
    };
  };
}
function onSuccess(name) {
  return (state, data) => {
    return {
      ...state,
      loading: false,
      [name]: data
    };
  };
}
function onError(name) {
  return state => {
    return {
      ...state,
      loading: false
    };
  };
}

function createReducer(name, methodName, initialState) {
  methodName = capitalizeFirstLetter(methodName);
  return {
    ["on" + methodName + "Request"]: onRequest(name, initialState),
    ["on" + methodName + "Success"]: onSuccess(name),
    ["on" + methodName + "Error"]: onError(name)
  };
}

function createEffect(methodName, service) {
  methodName = capitalizeFirstLetter(methodName);

  return async function(payload, rootState) {
    try {
      this["on" + methodName + "Request"]();
      let res = await service(payload);
      this["on" + methodName + "Success"](res);
      return res;
    } catch (e) {
      this["on" + methodName + "Error"](e);
      throw e;
    }
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
