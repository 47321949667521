import axios from "axios";
import firebase from "@firebase/app";

import server from "../config/server";
import { logout } from "../Pages/Auth/service";
import { history } from "../store";

export async function api() {
  // console.log("server.URL", server.URL)
  let opts = {
    baseURL: server.URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Frame-Options": `noopen`,
      'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
      'Public-Key-Pins': 'pin-sha256="AbCdEf123="; pin-sha256="ZyXwVu456="; max-age=7776000',
      'X-XSS-Protection': '1; mode=block',
      'X-Content-Type-Options': 'nosniff',

      // "Access-Control-Allow-Origin": "http://localhost:8000",
      // "Access-Control-Allow-Credentials": "true"
      // "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate"
    }
  };
  if (firebase.auth().currentUser) {
    try {
      opts.headers[
        "x-access-token"
      ] = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ false);
    } catch (e) {}
  }
  return axios.create(opts);
}

export function catchHandler(e) {
  console.error(e);
  let res = JSON.parse(JSON.stringify(e)).response;
  if (!res) {
    return history.push("/serviceDown");
  }
  //throw new Error("Server restarting, please try again later.");
  if (res.status === 401) {
    logout().then(() => history.push("/login"));
  }
  if (res && res.data) throw res.data;
  throw res;
}

export function requestHandler(promise) {
  return promise.then(res => res.data).catch(catchHandler);
}
