import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import { objToUrlParams } from "../../helpers/utils";

export async function createShareLink({ eventId, mediaId, message, password }) {
  return (await api())
    .post(server.apiMediaShareUrl(eventId, mediaId), { message, password })
    .then(res => res.data)
    .catch(catchHandler);
}

export async function updateGroupStatus({ status, mediaId, groupId, eventId, requestEditStart }) {
  let body = { status, groupId, eventId, mediaId, requestEditStart }
  return (await api())
    .post(server.apiGroupStatus(eventId, groupId), body)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function showSharedMedia(data) {
  let url
  if (data.password)
    url = `${server.apiSharedMedia(data.shareId)}?password=${data.password}`
  else
    url = `${server.apiSharedMedia(data.shareId)}`

  return (await api())
    .get(url)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function listMedia({ eventId, ...rest }) {
  return (await api())
    .get(server.apiMedia(eventId) + "?" + objToUrlParams(rest))
    .then(res => res.data)
    .catch(catchHandler);
}

export const getMedia = async ({ eventId, mediaId }) => {
  return (await api())
    .get(server.apiMedia(eventId) + "/" + mediaId)
    .then(res => res.data)
    .catch(catchHandler);
};
export const updateMedia = async ({ eventId, mediaId, data }) => {
  return (await api())
    .put(server.apiMedia(eventId) + "/" + mediaId, data)
    .then(res => res.data)
    .catch(catchHandler);
};
export const updateMedias = async ({ eventId, data }) => {
  return (await api())
    .put(server.apiMedia(eventId) + "/update-media-status" , data)
    .then(res => res.data)
    .catch(catchHandler);
};

export const listComments = async ({ eventId, mediaId }) => {
  return (await api())
    .get(server.apiMediaComments(eventId, mediaId))
    .then(res => res.data)
    .catch(catchHandler);
};


export const getTwilioToken = async ({ eventId, mediaId }) => {
  return (await api())
    .get(server.twilioToken(eventId, mediaId))
    .then(res => res.data)
    .catch(catchHandler);
};

export async function listEventComments(eventId) {
  return (await api())
    .get(server.apiEventComments(eventId))
    .then(res => res.data)
    .catch(catchHandler);
}


export const updateStatus = async (media_id, status) => {
  return (await api())
    .post(server.UPDATE_MEDIA, { id: media_id, file_status: status })
    .then(res => res.data)
    .catch(catchHandler);
};

export const mediaSocialShare = async (options) => {
  return (await api())
    .post(server.MEDIA_SOCIAL_SHARE, options)
    .then(res => res.data)
    .catch(catchHandler);
}

export const removeMedia = async (eventId, id) => {
  return (await api())
    .delete(server.apiMedia(eventId) + '/' + id)
    .then(res => res.data)
    .catch(catchHandler);
}

export const updateMediaName = async ({ id, event_id, file_name }) => {
  return (await api())
    .put(server.apiMedia(event_id) + '/' + id, { file_name: file_name })
    .then(res => res.data)
    .catch(catchHandler);
}

export const createNotes = async (notes) => {
  return (await api())
    .post(server.API_CREATE_NOTES, notes)
    .then(res => res.data)
    .catch(catchHandler);
};

export const listNotes = async (eventId) => {
  return (await api())
    .get(`${server.API_CREATE_NOTES}?eventId=${eventId}`)
    .then(res => res.data)
    .catch(catchHandler);
};
export const listActivities = async (filter) => {
  return (await api())
    .get(`${server.API_CREATE_LOGS}?event_id=${filter && filter.eventId}&action=${filter && filter.activityFilter && filter.activityFilter.commands}&orgId=${filter && filter.orgFilter}&field=${filter && filter.field}&order=${filter && filter.order}&user_id=${filter && filter.activityFilter && filter.activityFilter.activeUsers}&activityFromDate=${filter && filter.activityFromDate ? filter.activityFromDate : "undefined"}&activityToDate=${filter && filter.activityToDate ? filter.activityToDate : "undefined"}&myWallet=${filter && filter.myWallet ? filter.myWallet : "undefined"}`)
    .then(res => res.data)
    .catch(catchHandler);
};
export const listCommands = async (eventId) => {
  return (await api())
    .get(`${server.API_ALL_COMMANDS}?event_id=${eventId}`)
    .then(res => res.data)
    .catch(catchHandler);
};
export const listActiveUsers = async (eventId) => {
  return (await api())
    .get(`${server.API_ACTIVE_USERS}?eventId=${eventId}`)
    .then(res => res.data)
    .catch(catchHandler);
};
export const getS3Url = async () => {
  return (await api())
    .get(server.API_GET_URL)
    .then(res => res.data)
    .catch(catchHandler);
};

export const replaceThumbnail = async (thumbnailObj) => {
  return (await api())
    .put(server.apiThumbnailUpdate(thumbnailObj.eventId), thumbnailObj)
    .then(res => res.data)
    .catch(catchHandler);
};


export const createAnnotations = async (data) => {
  return (await api())
    .post(`/api/events/${data.eventId}/media/${data.mediaId}/media-annotations`, data)
    .then(res => res.data)
    .catch(catchHandler);
};
export const getMediaAnnotations = async (data) => {
  return (await api())
    .get(`/api/events/${data.eventId}/media/${data.mediaId}/media-annotations?mediaId=${data.mediaId}`, data)
    .then(res => res.data)
    .catch(catchHandler);
};
export const updateAnnotations = async (data) => {
  return (await api())
    .put(`/api/events/${data.eventId}/media/${data.mediaId}/media-annotations`, data)
    .then(res => res.data)
    .catch(catchHandler);
};
export const deleteNote = async (noteId) => {
  return (await api())
    .delete(`${server.API_CREATE_NOTES}?noteId=${noteId}`)
    .then(res => res.data)
    .catch(catchHandler);
};
export const onDeleteAnnotation = async (data) => {
  return (await api())
    .delete(`/api/events/${data.eventId}/media/${data.media_id}/media-annotations?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}


export const deleteGroupComment = async (comment) => {
  return (await api())
    .delete(server.apiGroupComments(comment) + '/' + comment.commentId)
    .then(res => res.data)
    .catch(catchHandler);
};
export const deleteMediaComment = async (comment) => {
  return (await api())
    .delete(server.apiMediaComments(comment.eventId, comment.mediaId) + '/' + comment.commentId)
    .then(res => res.data)
    .catch(catchHandler);
};
export const deleteEventComment = async (comment) => {
  return (await api())
    .delete(server.apiEventComments(comment.eventId) + '/' + comment.commentId)
    .then(res => res.data)
    .catch(catchHandler);
};
export async function transferOwnerShip({ eventId, transferTo }) {
  return (await api())
    .post(server.apiTransferOwner(eventId), {
      transferTo
    })
    .then(res => res.data)
    .catch(catchHandler);
};