import React from "react";

import { Spin, Icon } from "antd";

const styles = {
  container: {
    position: "relative",
    margin: "auto",
    padding: "15px 0",
    textAlign: "center"
  },
  refresh: {
    position: "relative",
    display: "block",
    margin: "0 auto"
  }
};

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default ({ style, ...rest }) => {
  return (
    <div style={{ ...styles.container, ...style, height:'100vh',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Spin size="large" indicator={antIcon} />
    </div>
  );
};
