import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
// import { api, requestHandler } from "../../helpers/axios";

class Service extends CrudService {
  // constructor(url) {
  //   super(url);
  // }
}

export default new Service(server.API_NOTIFICATION_TOKENS);
