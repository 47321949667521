import * as service from "./service";
import { notification, Modal } from "antd";
const confirm = Modal.confirm;

export default {
  state: {
    loading: false,
    allUsers: [],
    allUserDetails: [],
    userCompanies: [],
    unreadComments: [],
    undownloadMedia: [],
    editorDetails: [],
    availabeEditors: [],
    allEditors: [],
    editorsEvents: [],
    allOrgs: [],
    allOwners: [],
    getCreditsData: {},
    userLicenceDetails: [],
    listCards: []
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onGetUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allUsers: data
      };
    },
    onGetAllUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allUserDetails: data
      };
    },
    onGetUserCompaniesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userCompanies: data
      };
    },
    onGetMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        undownloadMedia: data
      };
    },
    onGetUnreadCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        unreadComments: data
      };
    },

    onGetUsersDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userLicenceDetails: data
      };
    },
    onGetEditorDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        editorDetails: data
      };
    },
    getAvailableEditorsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        availabeEditors: data
      };
    },

    onGetCardsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        listCards: data
      };
    },

    onGetAllEditorsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allEditors: data
      };
    },
    onGetAllOrganizationsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allOrgs: data
      };
    },
    onGetEditorsEventsListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        editorsEvents: data
      };
    },
    onGetAllOwnersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allOwners: data
      };
    },
    onGetActivityDatesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        activityDates: data
      };
    },
    onGetAllChatSuccess(state, data) {
      return {
        ...state,
        loading: false,
        getAllChats: data
      };
    },
    onGetCreditsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        getCreditsData: data
      };
    }
  },

  effects: {
    async deleteLicencedEditors(data) {
      try {
        this.onRequest();
        let res = await service.deleteLicencedEditors(data);
        await this.onSuccess(res);
        let eventId = {
          id: res.eventId
        };
        await this.getEditorDetails(eventId);
        await this.getAvailableEditorsDetails(eventId);
        await this.getAllEditors();
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getEditorsEventsList(data) {
      try {
        this.onRequest();
        let res = await service.getEditorsEventsList(data);
        this.onGetEditorsEventsListSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getAllEditors(data) {
      try {
        this.onRequest();
        let res = await service.getAllEditors(data);
        this.onGetAllEditorsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async addEventEditors(data) {
      try {
        this.onRequest();
        let res = await service.addEventEditors(data);
        await this.onGetUsersSuccess(res);
        let eventId = {
          id: res.event_id
        };
        await this.getEditorDetails(eventId);
        await this.getAvailableEditorsDetails(eventId);
        await this.getAllEditors();
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    // async getUsers({ limit, offset, ...rest }) {
    //   try {
    //     this.onRequest();
    //     let res = await service.getUsers({ limit, offset, ...rest });
    //     this.onGetUsersSuccess(res);
    //     return res;
    //   } catch (e) {
    //     this.onError(e);
    //     throw e;
    //   }
    // },

    async getAllUsers(data) {
      try {
        this.onRequest();
        let res = await service.getAllUsers(data);
        this.onGetAllUsersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async updateUserDetails(data, payload) {
      try {
        // this.onRequest();
        let res = await service.updateUserDetails(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getUserCompanies(data, payload) {
      try {
        this.onRequest();
        let res = await service.getUserCompanies(data);
        this.onGetUserCompaniesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async onSubmitCompanyDetails(data, payload) {
      try {
        this.onRequest();
        let res = await service.onSubmitCompanyDetails(data);
        this.onSuccess(res);
        let companyList = {
          id: data.ownerId
        };
        this.getUserCompanies(companyList);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async deleteUser(data, payload) {
      try {
        this.onRequest();
        let res = await service.deleteUser(data);
        await this.onSuccess(res);
        await this.getUsersDetails(res);
        await this.getAllUsers(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async deleteEventEditor(data, payload) {
      try {
        this.onRequest();
        let res = await service.deleteEventEditor(data);
        await this.onSuccess(res);
        let details = {
          id: data.user_id
        }
        await this.getEditorsEventsList(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async checkUser(data, payload) {
      try {
        this.onRequest();
        let res = await service.checkUser(data);
        await this.onSuccess(res);

        return confirm({
          title:
            res && res.status === true
              ? `This user is a member of ${res.events.length} events`
              : "Are you sure you want to remove this user?",
          content: "Are you sure you want to remove this user?",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk: () => {
            this.deleteUser(res.body);
          },
          onCancel() {}
        });

        // return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async addUser(data, payload) {
      try {
        this.onRequest();
        let res = await service.addUser(data);
        await this.onSuccess(res);
        let user = {
          id: data.currentUser
        };
        await this.getUsersDetails(user);
        if (res) {
          if (res.status === false) {
            return notification.error({
              message: res.message,
              description: res.message
            });
          } else {
            notification.success({
              message: res.message,
              description: res.message
            });
          }
        }
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async editCredits(data, payload) {
      try {
        this.onRequest();
        let res = await service.editCredits(data);
        await this.onSuccess(res);
        if (res === "success") {
          notification.success({
            message: "Removed credits successfully",
            description: "Removed credits successfully"
          });
        }
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async removeEventCredits(data, payload) {
      try {
        this.onRequest();
        let res = await service.removeEventCredits(data);
        await this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async deleteCard(data, payload) {
      try {
        this.onRequest();
        let res = await service.deleteCard(data);
        await this.onSuccess(res);
        await this.getCards(data.userId);
        if (res) {
          if (res.status === false) {
            return notification.error({
              message: res.message,
              description: res.message
            });
          } else {
            notification.success({
              message: res.message,
              description: res.message
            });
          }
        }
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async payOnInvoice(data, payload) {
      try {
        this.onRequest();
        let res = await service.payOnInvoice(data);
        await this.onSuccess(res);
        let details = {
          id: data.userId
        };
        await this.getUsersDetails(details);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getUnreadComments(data, payload) {
      try {
        this.onRequest();
        let res = await service.getUnreadComments(data);
        this.onGetUnreadCommentsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async setDefaultCard(data, payload) {
      try {
        this.onRequest();
        let res = await service.setDefaultCard(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async addCard(data, payload) {
      try {
        this.onRequest();
        let res = await service.addCard(data);
        await this.onSuccess(res);
        await this.getCards(data.userId);
        let details = {
          id: data.userId
        };
        await this.getUsersDetails(details);

        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async getUnDownloadedMedia(data, payload) {
      try {
        this.onRequest();
        let res = await service.getUnDownloadedMedia(data);
        this.onGetMediaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async addLicenceDetails(details) {
      try {
        this.onRequest();
        let res = await service.addLicenceDetails(details);
        this.onSuccess(res);
        let data = {
          id: res.userCredits && res.userCredits.id
        };
        await this.getUsersDetails(data);
        await this.getAllUsers(data);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getUsersDetails(data) {
      try {
        this.onRequest();
        let res = await service.getUsersDetails(data);
        this.onGetUsersDetailsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async customValue(data) {
      try {
        this.onRequest();
        let res = await service.customValue(data);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async sendCredits(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.sendCredits(payload);
        this.onSuccess(res);
        let data = {
          id: payload.userId
        };
        this.getUsersDetails(data);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    async transferOwner(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.transferOwner(payload);
        this.onSuccess(res);
        if (res) {
          if (res.status === false) {
            return notification.error({
              message: res.message,
              description: res.message
            });
          } else {
            notification.success({
              message: "Transfered ownership successfully",
              description: "Transfered ownership successfully"
            });
          }
        }
        let data = {
          id: payload.userId
        };
        this.getUsersDetails(data);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

    // async getActivityDates() {
    //   try {
    //     this.onRequest();
    //     let res = await service.getActivityDates();
    //     this.onGetActivityDatesSuccess(res);
    //     return res;
    //   } catch (e) {
    //     this.onError(e);
    //     throw e;
    //   }
    // },
    async getCards(data) {
      try {
        this.onRequest();
        let res = await service.getCards(data);
        this.onGetCardsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async sendTransaction(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.sendTransaction(payload);
        let data = {
          id: payload.userId
        };
        await this.getUsersDetails(data);
        // if (res) {
        //   if (res.status === false) {
        //     return notification.error({
        //       message: res.message,
        //       description: res.message
        //     });
        //   } else {
        //     notification.success({
        //       message: res.message,
        //       description: res.message
        //     });
        //   }
        // }
        this.onSuccess(res);
        return res
      } catch (e) {
        this.onError(e);
        throw e
      }
    },
    async updateLicenceDetails(details) {
      try {
        this.onRequest();
        let res = await service.updateLicenceDetails(details);
        this.onSuccess(res);
        if (res && res.userCredits && res.userCredits.id) {
          let data = {
            id: res.userCredits.id
          };
          this.getUsersDetails(data);
          await this.getAllUsers(data);
        }
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getEditorDetails(data, payload) {
      try {
        this.onRequest();
        let res = await service.getEditorDetails(data);
        this.onGetEditorDetailsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getAllOrganizations(data, payload) {
      try {
        this.onRequest();
        let res = await service.getAllOrganizations();
        this.onGetAllOrganizationsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getAllOwners(data, payload) {
      try {
        this.onRequest();
        let res = await service.getAllOwners(data);
        this.onGetAllOwnersSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getAvailableEditorsDetails(data, payload) {
      try {
        this.onRequest();
        let res = await service.getAvailableEditorsDetails(data);
        this.getAvailableEditorsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createChatCommand(data, payload) {
      try {
        this.onRequest();
        let res = await service.createChatCommand(data);
        await this.onSuccess(res);
        if (res) {
          if (res.status === 400) {
            return notification.error({
              message: "Command Name already exists",
              description: "Command Name already exists"
            });
          }
          if (res.id) {
            notification.success({
              message: "Command added successfully",
              description: "Command added successfully"
            });
          }
        }
        await this.getAllChat();
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getAllChat(data) {
      try {
        this.onRequest();
        let res = await service.getAllChat(data);
        this.onGetAllChatSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async getCredits(data) {
      try {
        this.onRequest();
        let res = await service.getCredits(data);
        this.onGetCreditsSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async onDeleteCommand(record) {
      try {
        this.onRequest();
        let res = await service.onDeleteCommand(record);
        await this.onSuccess(res);
        await notification.success({
          message: "Chat Command removed successfully",
          description: "Chat Command removed successfully"
        });
        await this.getAllChat();
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async updateChatCommand(record) {
      try {
        this.onRequest();
        let res = await service.updateChatCommand(record);
        await this.onSuccess(res);
        if (res) {
          if (res.status === 400) {
            return notification.error({
              message: "Command Name already exists",
              description: "Command Name already exists"
            });
          }
          if (res.id) {
            notification.success({
              message: "Command edited successfully",
              description: "Command edited successfully"
            });
          }
        }
        await this.getAllChat();
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async removeOrganisation(datas) {
      try {
        this.onRequest();
        let res = await service.removeOrganisation(datas);
        this.onSuccess(res);
        let data = {
          id: res.userCredits && res.userCredits.id
        };
        this.getUsersDetails(data);
        await this.getAllUsers(data);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async createTransaction(data, payload) {
      try {
        this.onRequest();
        let res = await service.createTransaction(data);
        await this.onSuccess(res);
        await this.getCards(data.userId);
        let details = {
          id: data.userId
        };
        await this.getUsersDetails(details);

        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
  }
};
