import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
import { api,catchHandler, requestHandler } from "../../helpers/axios";
class Service extends CrudService {
  constructor(url) {
    super(url);
    this.setUrl = this.setUrl.bind(this);
  }
  setUrl() {
    this.url = server.apiGroupMedia.apply(null, arguments);
  }
  async updateMediaName({id,event_id,file_name}) {
    return (await api())
    .put(server.apiMedia(event_id) +'/'+id, { file_name: file_name })
    .then(res => res.data)
    .catch(catchHandler);
  }

  async shows(id) {
    let url = this.url;
    if (id) url += "/" + id;
    return requestHandler((await api()).get(url));
  }

  async removeGroupMedia (data) {
    return (await api())
      .delete(`${server.removeGroupMedias(data.event_id, data.groupId)}?groupId=${data.groupId}&&media_id=${data.media_id}`)
      .then(res => {
        // this.list()
        return res.data
      })
      .catch(catchHandler);
  }
}

export default new Service();
