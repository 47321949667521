import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
import { api, requestHandler } from "../../helpers/axios";

class Service extends CrudService {
  constructor(url) {
    super(url);
    this.setUrl = this.setUrl.bind(this);
    this.invite = this.invite.bind(this);
  }
  setUrl() {
    this.url = server.apiEventUsers.apply(null, arguments);
  }
  async invite(data) {
    return requestHandler((await api()).post(this.url + "/invite", data));
  }
}

export default new Service();
