import React from "react";
import ReactDOM from "react-dom";
import "./assets/style.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import { unregister } from './registerServiceWorker';
import { init} from "./config/firebase";

init();
// ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
ReactDOM.render(<App />, document.getElementById('root'));
unregister()
