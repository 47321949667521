import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { history } from "./../../store";
import { Modal, Button } from "antd";
import { logs } from "../../helpers/utils";

// import "./assets/antd.css";

export default class TimeOut extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      timeout: 600000,
      isIdle: false,
      visible: false,
      confirmModal: false
    };
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.reset = this._reset.bind(this);
    this.pause = this._pause.bind(this);
    this.resume = this._resume.bind(this);
    this.changeTimeout = this._changeTimeout.bind(this);
  }

  componentDidMount() {
    setInterval(async () => {
      // console.log("Time", this.idleTimer && this.idleTimer.getRemainingTime());
      logs("Player Details", window.videoRunning);

      if (window.annotationView === true)
        this.idleTimer.pause();

      if (window.videoRunning === true)
        this.idleTimer.pause();

      if (window.videoRunning === false)
        this.idleTimer.resume();

      if (window.uploadingFiles === "uploading") {
        this.idleTimer.pause();
      }
      if (
        window.uploadingFiles === "done" ||
        window.uploadingFiles === "removed"
      ) {
        this.idleTimer.resume();
        // this.resume();
        window.uploadingFiles = "";
      }

      let sharedPageCheck = history.location.pathname.search("/shared/media")
      if (sharedPageCheck !== -1) {
        this.idleTimer.pause();
      }
      if (this.idleTimer && this.idleTimer.getRemainingTime() < 300000) {
        if (history.location.pathname !== "/login") {
          if (sharedPageCheck === -1) {
              if (this.state.confirmModal === false) {
                this.setState({
                  visible: true,
                  confirmModal: true
                });
              }
          }
        }
      }
    }, 2000);
  }

  _onActive() {
    this.setState({ isIdle: false });
  }

  async _onIdle() {
    await this.props.logoutUser();
    await this.setState({ visible: false, isIdle: true, confirmModal: false });
  }

  _changeTimeout() {
    this.setState({
      timeout: this.refs.timeoutInput.state.value()
    });
  }

  _reset() {
    this.idleTimer.reset();
  }

  _pause() {
    this.idleTimer.pause();
  }

  _resume() {
    this.idleTimer.resume();
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      confirmModal: false
    });
  };

  render() {
    //   DEFAULT_EVENTS = [
    //     'mousemove',
    //     'keydown',
    //     'wheel',
    //     'DOMMouseScroll',
    //     'mouseWheel',
    //     'mousedown',
    //     'touchstart',
    //     'touchmove',
    //     'MSPointerDown',
    //     'MSPointerMove'
    //   ]
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          // events= {DEFAULT_EVENTS}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={this.state.timeout}
          startOnLoad
        />

        <Modal
          visible={this.state.visible}
          title="Your session will be expired soon"
          footer={null}
          closable={false}
        // onOk={this.hideModal}
        // onCancel={this.hideModal}
        // okText="Ok"
        // cancelText="Cancel"
        >
          <p>
            You have been idle for too long and your session will be expired in
            1 minute. To keep working, click Continue button
          </p>
          <Button
            type="primary"
            style={{ marginLeft: "80%" }}
            onClick={this.hideModal}
          >
            Continue
          </Button>
        </Modal>
      </div>
    );
  }
}
