import * as service from "./service";

export default {
  state: {
    loading: false,
    user: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onMeSuccess(state, data) {
      let userPermissions = Object.values(data.permissions)
      return {
        ...state,
        loading: false,
        user: data,
        userPermissions:userPermissions
      };
    }
  },
  effects: {
    async me(payload, rootState) {
      try {
        this.onRequest();
        if (
          payload ||
          !rootState.authModel.user.email ||
          window.user !== rootState.authModel.user.email
        ) {
          let res = await service.me();
          this.onMeSuccess(res);
          return res;
        }
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async login(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.login(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        await service.logout();
        this.onError(e);
        throw e;
      }
    },
    async register(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.register(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async logout(payload, rootState) {
      try {
        this.onRequest();
        if(global.fcm_token){
          await service.removeNotificationToken();
        }
        let res = await service.logout();
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async resetPassword(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.resetPassword(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    }
  }
};
