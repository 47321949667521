import { api, requestHandler } from "./axios";
import { objToUrlParams } from "./utils";

export default class {
  constructor(url) {
    this.url = url;
    this.list = this.list.bind(this);
    this.show = this.show.bind(this);
    this.update = this.update.bind(this);
    this.create = this.create.bind(this);
    this.remove = this.remove.bind(this);
  }
  async list(query) {
    let url = this.url;
    if (query && typeof query === "object") {
      url += "?" + objToUrlParams(query);
    }
    return requestHandler((await api()).get(url));
  }
  async show(id) {
    let url = this.url;
    if (id) url += "/" + id;
    return requestHandler((await api()).get(url));
  }
  async update(data) {
    let url = this.url;
    if (data.id) url += "/" + data.id;
    return requestHandler((await api()).put(url, data));
  }
  async create(data) {
    let url = this.url;
    return requestHandler((await api()).post(url, data));
  }
  async remove(id) {
    let url = this.url;
    if (id) url += "/" + id;
    return requestHandler((await api()).delete(url));
  }

}
