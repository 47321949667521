import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

const model = reduxRematchModel([
  {
    groupComments: [],
    listGroupComments: service.list
  },
  {
    groupComment: {},
    getGroupComment: service.show
  },
  {
    groupComment: {},
    addGroupComment: service.create
  }
]);

model.reducers.initGroupCommentsModel = (state, payload) => {
  service.setUrl(payload);
  return state;
};

export default model;
