import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
import { api, catchHandler } from "../../helpers/axios";
class Service extends CrudService {
  // constructor(url) {
  //   super(url);
  // }
  async update(data,userId){
    return (await api())
    .put(server.API_USERS + "/" + userId, data)
    .then(res => res.data)
    .catch(catchHandler);
  }
  async payOnInvoice(data){
    return (await api())
    .post(server.API_PAY_ON_INVOICE, data)
    .then(res => res.data)
    .catch(catchHandler);
  }
  async transferEventOwnerShip(data){
    return (await api())
    .post(server.API_TRANSFER_OWNERSHIP, data)
    .then(res => res.data)
    .catch(catchHandler);
  }
  async pusherIsTyping (data) {
    return (await api())
      .post(`/api/pusher/user-typing`, data)
      .then(res => res.data)
      .catch(catchHandler);
  };
  async addComment(payload) {
    return (await api()) 
      .post(`${server.API_DEPARTMENTS}/${payload.departmentId}/comments`, payload.data)
      .then(res => res.data)
      .catch(catchHandler)
  }
  async addEventsComment({ eventId, message, char }) {
    return (await api())
      .post(server.apiEventComments(eventId), {
        message,
        char
      })
      .then(res => res.data)
      .catch(catchHandler);
  }
  async addMediaComment(payload) {
    return (await api())
      .post(`${server.API_DEPARTMENTS}/${payload.departmentId}/media/${payload.mediaId}/comments`, payload.data)
      .then(res => res.data)
      .catch(catchHandler)
  }

async sendComment({ eventId, mediaId, message, char }) {
  return (await api())
    .post(server.apiMediaComments(eventId, mediaId), {
      message, char
    })
    .then(res => res.data)
    .catch(catchHandler);
}
}
export default new Service(server.API_USERS);
