import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

const model = reduxRematchModel([
  {
    eventUsers: [],
    listEventUsers: service.list
  },
  {
    eventUser: {},
    removeEventUser: service.remove
  },
  {
    eventUser: {},
    addEventUser: service.create
  },
  {
    eventUser: {},
    addEventUser: service.create
  },
  {
    result: null,
    invite: service.invite
  }
]);

model.reducers.initEventUserModel = (state, payload) => {
  service.setUrl(payload);
  return state;
};

export default model;
