import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import {objToUrlParams} from '../../helpers/utils'

export async function list(payload) {
  return (await api())
    .get(`${server.NOTIFICATIONS}?${objToUrlParams({limit: payload.limit, offset: payload.offset})}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function readAll() {
  return (await api())
    .put(server.NOTIFICATIONS + "/read-all")
    .then(res => res.data)
    .catch(catchHandler);
}

export async function update(data) {
  return (await api())
    .put(server.NOTIFICATIONS + "/" + data.id, data)
    .then(res => res.data)
    .catch(catchHandler);
}
