export function isAdmin(user) {
  return user && user.role === 1;
}

export function isSuperAdmin(user) {
  return user && user.role === 3;
}

export function isEventAdmin(userId,eventAdmin) {
  return userId===eventAdmin;
}

export function isEditor(user) {
  return user && user.role === 2;
}

export function isUser(user) {
  return user && user.role === 0;
}