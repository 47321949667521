import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import {objToUrlParams} from '../../helpers/utils'

export async function listYourVideos(payload) {
  return (await api())
    .get(`${server.USER_MEDIA_UPLOAD}?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function listUploadedVideos(payload) {
  return (await api())
    .get(`${server.USER_MEDIA_UPLOAD}/uploaded?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function addVideo(payload) {
  return (await api())
    .post(`${server.USER_MEDIA_UPLOAD}/add`, payload)
    .then(res => res.data)
    .catch(catchHandler)
} 

export async function deleteVideo(payload) {
  return (await api())
    .delete(`${server.USER_MEDIA_UPLOAD}/${payload.mediaId}?type=${payload.type}`)
    .then(res => res.data)
    .catch(catchHandler)
} 

export async function getVideo(payload) {
  return (await api())
    .get(`${server.USER_MEDIA_UPLOAD}/${payload.mediaId}?type=${payload.type}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export async function downloadVideo(payload, type) {
  return (await api())
    .post(`${server.USER_MEDIA_UPLOAD}/media-download?type=${type}`, {...payload, type})
    .then(res => res.data)
    .catch(catchHandler)
}