import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

const model = reduxRematchModel([
  {
    groups: [],
    listGroups: service.list
  },
  {
    group: {},
    getGroup: service.show
  },
  {
    group: {},
    addGroup: service.create
  },
  {
    group: {},
    updateGroup: service.update
  }
]);

model.reducers.initGroupModel = (state, payload) => {
  service.setUrl(payload);
  return state;
};

export default model;
