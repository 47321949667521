import * as service from "./service";
import { notification } from "antd";

export default {
  state: {
    loading: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      }
    },
    onError(state) {
      return {
        ...state,
        loading: false
      }
    },
    onSuccess(state) {
      return {
        ...state,
        loading: false
      }
    },
    onListDepartmentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departments: data,
      }
    },
    onCreateDepartmentSuccess(state, data) {
      notification.success({
        message: 'Success',
        description: 'New department added successfully'
      })
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteDepartmentSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onGetDepartmentSuccess(state, data) {
      return {
        ...state,
        loading: false,
        department: data
      }
    },
    onAddDepartmentUsersSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onDeleteDepartmentUsersSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onInviteUserSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onListDepartmentUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departmentUsers: data
      }
    },
    onAddMediaSuccess(state, data) {
      // console.log("data",data)
      let message = `${data.accepted && data.accepted.length ? `${data.accepted.length} medias added. ` : ''} ${data.rejected && data.rejected.length ? `${data.rejected.length} medias already exists in department` : ''}`
      if (data.accepted && data.accepted.length)
        notification.success({
          message: 'Success',
          description: message
        })
      else
        notification.info({
          message: 'Could not add',
          description: message
        })
      return {
        ...state,
        loading: false
      }
    },
    onListMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departmentMedia: data
      }
    },
    onGetMediaSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departmentShowMedia: data
      }
    },
    onDeleteMediaSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onDepartmentMediaStatusSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onListDepartmentCommentsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departmentComments: data
      }
    },

    onListMediaCommentSuccess(state, data) {
      return {
        ...state,
        loading: false,
        departmentMediaComments: data
      }
    }
  },
  effects: {
    async listDepartments(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listDepartments(payload)
        this.onListDepartmentsSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async createDepartment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.createDepartment(payload)
        this.onCreateDepartmentSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async deleteDepartment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.deleteDepartment(payload)
        this.onDeleteDepartmentSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async getDepartment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.getDepartment(payload)
        this.onGetDepartmentSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async addDepartmentUsers(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.addDepartmentUsers(payload)
        this.onAddDepartmentUsersSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async deleteDepartmentUser(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.deleteDepartmentUsers(payload)
        this.onDeleteDepartmentUsersSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async inviteUserToDepartment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.inviteUser(payload)
        this.onInviteUserSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async listDepartmentUsers(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listUsers(payload)
        this.onListDepartmentUsersSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async addMediaToDepartment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.addMedia(payload)
        this.onAddMediaSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async listDepartmentMedia(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listMedia(payload)
        this.onListMediaSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async getDepartmentMedia(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.getMedia(payload)
        this.onGetMediaSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async deleteDepartmentMedia(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.deleteMedia(payload)
        this.onDeleteMediaSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async departmentMediaDownloadStatus(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.downloadMedia(payload)
        this.onDepartmentMediaStatusSuccess(res)
        return res
      }
      catch (e) {
        this.onError(e)
        throw e
      }
    },
    async listDepartmentComments(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listComments(payload)
        this.onListDepartmentCommentsSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },

    async listDepartmentMediaComments(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.listMediaComments(payload)
        this.onListMediaCommentSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },

    async deleteDepartmentComment(comment) {
      try {
        this.onRequest();
        let res = await service.deleteDepartmentComment(comment);
        this.onSuccess(res);
        // await this.getMediaAnnotations(details)
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },

  }
}