import React, { Component } from "react";
import firebase from "@firebase/app";
import { Route, Redirect, Router, Switch } from "react-router-dom";

import { getCurrentUser } from "./config/firebase";
import asyncComponent from "./helpers/async-func";
import Layout from "./Containers/Layout";

import Loader from "./Components/Loader";

const RestrictedRoute = ({
  component: Component,
  layoutSettings = {},
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        firebase.auth().currentUser ? (
          <div>
            <Layout settings={layoutSettings}>
              <Component {...props} />
            </Layout>
          </div>

        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );

const UnRestrictedRoute = ({
  component: Component,
  layoutSettings = {},
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        !firebase.auth().currentUser ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );

const PublicRoute = ({
  component: Component,
  layoutSettings = {},
  ...rest
}) => <Route {...rest} render={props => <Component {...props} />} />;

export default class extends Component {
  state = {
    loading: true
  };
  async componentDidMount() {
    await this.getUser();
  }

  async getUser() {

    try {
      const user = await getCurrentUser();
      this.setState({
        user,
        loading: false
      });
    } catch (e) {
      console.error(e);
      this.setState({
        user: null,
        loading: false
      });
    }
  }

  render() {
    const { history } = this.props;
    const { loading } = this.state;
    if (loading) return <Loader />;
    return (
      <Router history={history}>
        <Switch>
          <UnRestrictedRoute
            exact
            path={"/login"}
            component={asyncComponent(() => import("./Pages/Auth/Login"))}
          />
          <UnRestrictedRoute
            exact
            path={"/register"}
            component={asyncComponent(() => import("./Pages/Auth/Register"))}
          />
          <UnRestrictedRoute
            exact
            path={"/forget-pwd"}
            component={asyncComponent(() => import("./Pages/Auth/ForgotPwd"))}
          />
          <PublicRoute
            exact
            path={"/pay-on-invoice"}
            component={asyncComponent(() => import("./Pages/EmailPages/PayOnInvoice"))}
          />
          <PublicRoute
            exact
            path={"/transfer-event-ownership"}
            component={asyncComponent(() => import("./Pages/EmailPages/TransferOwnership"))}
          />
          <RestrictedRoute
            exact
            path={"/events/:eventId/users"}
            layoutSettings={{
              title: "Event Users",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/EventUser"))}
          />
          <RestrictedRoute
            exact
            path={"/events/:eventId/edit"}
            layoutSettings={{
              title: "Event",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Events/Edit"))}
          />
          <RestrictedRoute
            exact
            path={"/events/:eventId/preview-text"}
            layoutSettings={{
              title: "Event Preview Text",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Events/PreviewText"))}
          />
          <RestrictedRoute
            exact
            path={"/events/:eventId/groups/:groupId"}
            layoutSettings={{
              title: "Groups",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/GroupMedia"))}
          />
          <RestrictedRoute
            exact
            path={"/events/:eventId/media/:mediaId"}
            layoutSettings={{
              title: "Events",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Media/Show"))}
          />
          <PublicRoute
            exact
            path={"/shared/media/:shareId"}
            component={asyncComponent(() => import("./Pages/Media/Shared"))}
          />
          <RestrictedRoute
            exact
            path={"/registration-requests"}
            layoutSettings={{
              title: "Registration Requests"
            }}
            component={asyncComponent(() =>
              import("./Pages/RegistrationRequests")
            )}
          />
          <RestrictedRoute
            exact
            path={"/operations"}
            layoutSettings={{
              title: "Operations"
            }}
            component={asyncComponent(() =>
              import("./Pages/Operations")
            )}
          />
          <RestrictedRoute
            exact
            path={"/FAQ"}
            layoutSettings={{
              title: "FAQ"
            }}
            component={asyncComponent(() =>
              import("./Pages/FAQ")
            )}
          />
          <RestrictedRoute
            exact
            path={"/Support"}
            layoutSettings={{
              title: "Contact Us"
            }}
            component={asyncComponent(() =>
              import("./Pages/ContactUs")
            )}
          />

          <RestrictedRoute
            exact
            path={"/admin-board"}
            layoutSettings={{
              title: "Admin Board"
            }}
            component={asyncComponent(() => import("./Pages/AdminBoard"))}
          />

          <RestrictedRoute
            exact
            path={"/shared-links"}
            layoutSettings={{
              title: "Shared Link"
            }}
            component={asyncComponent(() => import("./Pages/SharedLinks"))}
          />
          <RestrictedRoute
            exact
            path={"/my-profile"}
            layoutSettings={{
              title: "Profile Settings"
            }}
            component={asyncComponent(() => import("./Pages/MyProfile"))}
          />
          {[
            '/department',
            '/department/:departmentId'
          ].map((path, index) =>
            <RestrictedRoute
              exact
              key={index}
              path={path}
              layoutSettings={{
                title: "Department",
                showSidebar: true
              }}
              component={asyncComponent(() => import("./Pages/Departments"))}
            />)}

          <RestrictedRoute
            exact
            path={"/serviceDown"}
            layoutSettings={{
              title: "Service Down",
              showSidebar: false
            }}
            component={asyncComponent(() => import("./Components/ServiceDown"))}
          />

          <RestrictedRoute
            exact
            path={"/department/:departmentId/media/:mediaId"}
            layoutSettings={{
              title: "Department",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Departments/Show"))}
          />

          <RestrictedRoute
            exact
            path={"/videos"}
            layoutSettings={{
              title: "Your Videos",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Videos"))}
          />

          <RestrictedRoute
            exact
            path={"/videos/:mediaId"}
            layoutSettings={{
              title: "Your Videos",
              showSidebar: true
            }}
            component={asyncComponent(() => import("./Pages/Videos/show"))}
          />

          {[
            "/events/:eventId/media",
            "/events/:eventId",
            "/events",
            "/home",
            ""
          ].map((path, i) => (
            <RestrictedRoute
              exact
              key={i}
              path={path}
              layoutSettings={{
                title: "Home",
                showSidebar: true
              }}
              component={asyncComponent(() => import("./Pages/Media/List"))}
            />
          ))}

        </Switch>
      </Router>
    );
  }
}
