import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import { objToUrlParams } from "../../helpers/utils";

export async function listEvents(query) {
  return (await api())
    .get(server.API_EVENT + "?" + objToUrlParams(query))
    .then(res => res.data)
    .catch(catchHandler);
}

export async function eventsStatus(data) {
  let eventId = {
    id: data
  };
  return (await api())
    .post('/api/event-status', eventId)
    .then(res => res.data).catch(catchHandler);
}

export async function getUsers({ limit, offset, value, order, ...rest }) {
  let apiUrl;
  if (order && order.field) {
    apiUrl = `${server.API_USERS}?limit=${limit}&offset=${offset}&field=${
      order.field
      }&order=${order.order}`;
  }
  else if (value) {
    apiUrl = `${
      server.API_USERS
      }?limit=${limit}&offset=${offset}&value=${value}`;
  }
  else {
    apiUrl = `${server.API_USERS}?limit=${limit}&offset=${offset}`;
  }
  return (await api())
    .get(apiUrl)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getEvent(id) {
  return (await api())
    .get(server.API_EVENT + "/" + id)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function updateEvent(event) {
  return (await api())
    .put(server.API_EVENT + "/" + event.id, event.data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function deleteEvent(id) {
  return (await api())
    .delete(server.DELETE_EVENT + "/" + id)
    .then(res => res.data)
    .catch(catchHandler);
}

export const createEvent = async event => {
  if (event.files && event.files.length) {
    var data = new FormData();
    event.files.map(file => {
      return data.append("file", file);
    });
    // var config = {
    //   headers: {
    //     "content-type": "multipart/form-data;"
    //   }
    // };
  }
  return (await api())
    .post(server.CREATE_EVENT, event)
    .then(res => res.data)
    // .then(async res => {
    //   if (event.files && event.files.length) {
    //     data.append("event", res.data);
    //     return (await api()).post(server.CREATE_EVENT_ASSETS, data, config);
    //   } else {
    //   }
    // })
    // .then(response => {
    // })
    .catch(catchHandler);
};

export const mediaDownload = async data => {
  return (await api())
    .post(
      server.API_EVENT + "/" + data.eventId + server.MEDIA_DOWNLOAD,
      data.payload
    )
    .then(res => res.data)
    .catch(catchHandler);
};

export const mediaStatusUpdate = async data => {
  return (await api())
    .post(
      server.API_EVENT + "/" + data.eventId + server.MEDIA_DOWNLOAD_STATUS,
      data
    )
    .then(res => res.data)
    .catch(catchHandler);
};

export const getMediaForDownload = async ({ eventId, mediaId }) => {
  return (await api())
    .get(server.apiMedia(eventId) + "/" + mediaId)
    .then(res => res.data)
    .catch(catchHandler);
};

export async function createEventTransaction(data) {
  return (await api())
    .post(`${server.API_EVENT}/${data.event_id}/transactions`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getEventTransaction(data) {
  return (await api())
    .get(`${server.API_EVENT}/${data.id}/transactions?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler);
}