import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

export default reduxRematchModel([
  {
    registrationRequests: {
      limit: 10,
      offset: 0,
      docs: []
    },
    list: service.list
  },
  {
    registrationRequest: {},
    update: service.update
  },
  {
    registrationRequest: {},
    approve: service.approve
  },
  {
    registrationRequest: {},
    reject: service.reject
  },
  {
    registrationRequest: {},
    updateUser: service.updateUser
  },
]);
