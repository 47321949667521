import CrudService from "../../helpers/crud-service";
import server from "../../config/server";
import { api, requestHandler } from "../../helpers/axios";

class Service extends CrudService {
  constructor(url) {
    super(url);
    this.approve    = this.approve.bind(this);
    this.reject     = this.reject.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }
  async approve(id) {
    let url = this.url;
    url += "/" + id + "/approve";
    return requestHandler((await api()).patch(url));
  }
  async reject(id) {
    let url = this.url;
    url += "/" + id + "/reject";
    return requestHandler((await api()).patch(url));
  }
  async updateUser(record){
    let url   = this.url;
    url       += "/" + record.id + "/update_user_role";
    let data  = {email: record.email,role:record.role}
    return requestHandler((await api()).patch(url,data));
  }
}

export default new Service(server.API_REGISTRATION_REQUESTS);
