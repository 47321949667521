import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

const model = reduxRematchModel([
  {
    groupMedias: {
      docs: []
    },
    listGroupMedias: service.list
  },
  {
    groupMedia: {},
    getGroupMedia: service.show
  },
  {
    groupMedia: {},
    addGroupMedia: service.create,
    addBulkGroupMedia : service.create
  },
  {
    groupMedia: {},
    updateMediaName:service.updateMediaName
  },
  {
    groupMedia: {},
    removeGroupMedia:service.removeGroupMedia
    // listGroupMedias: service.list
  }
]);

model.reducers.initGroupMediaModel = (state, payload) => {
  service.setUrl(payload);
  return state;
};

export default model;
