import reduxRematchModel from "../../helpers/redux-rematch-model";
import service from "./service";

export default reduxRematchModel([
  {
    sharedLinks: {
      limit: 10,
      offset: 0,
      docs: []
    },
    list: service.list
  },
  {
    sharedLinks: {},
    delete: service.delete
  }
]);
