import firebase from "@firebase/app";

export function fb() {
  const provider = new firebase.auth.FacebookAuthProvider();
  return firebase.auth().signInWithPopup(provider);
}

export function google() {
  var provider = new firebase.auth.GoogleAuthProvider();

  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

  return firebase.auth().signInWithPopup(provider);
}

export function local(email, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // if (errorCode === "auth/wrong-password") {
      //   throw "Wrong password.";
      // } else {
      //   alert(errorMessage);
      // }
      throw error;
    });
}
