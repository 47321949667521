import firebase from "@firebase/app";

import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import * as firebaseAuth from "../../helpers/firebase-auth";

export async function login({ provider, userName, password, remember }) {
  await removeToken(userName);
  return firebase
    .auth()
    .setPersistence(
      firebase.auth.Auth.Persistence[remember ? "LOCAL" : "SESSION"]
    )
    .then(function() {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return firebaseAuth[provider](userName, password);
    })
    .then(function(result) {
      // Send token to your backend via HTTPS
      // ...
      return checkToken(provider);
    })
    .then(function(result) {
      // Send token to your backend via HTTPS
      // ...
      return auth(provider);
    })
    // .then(function(result) {
    //   // Send token to your backend via HTTPS
    //   // ...
    //   return checkToken(provider);
    // })
}

export async function checkToken() {
  return (await api())
    .post(server.CHECK_AUTH)
    .then(res => {
      return  res.data})
    .catch(catchHandler);
}

export async function removeToken(email) {
  let data = {
    email: email
  }
  return (await api())
    .post(server.REMOVE_TOKEN, data)
    .then(res => {
      return  res.data})
    .catch(catchHandler);
}

export async function logout() {
  await document.body.classList.remove('sidemenu-enabled', 'home-body-wrapper');
  return (await api())
  .post("/api/logout")
  .then(res => {
    return  firebase.auth().signOut();})
  .catch(catchHandler);
  // return firebase.auth().signOut();
}

export async function removeNotificationToken(){
  return (await api())
    .delete(server.API_NOTIFICATION_TOKENS + '/' + global.fcm_token)
    .then(res => res.data)
    .catch(catchHandler);
}
export async function auth(provider, data) {
  return (await api())
    .post(server.AUTH + provider, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function register(data) {
  return (await api())
    .post(server.API_REGISTRATION_REQUESTS, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function me() {
  return (await api())
    .get(server.ME)
    .then(res => res.data)
    .catch(catchHandler);
}

export function resetPassword(emailAddress) {
  return firebase.auth().sendPasswordResetEmail(emailAddress);
}
