import React, { Component } from "react";
import { Menu, Icon, List, Spin, Tooltip } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';


export const notificationTypes = [
  "MEDIA:SHARE",
  "EVENTUSER:INVITE",
  "EVENTUSER:ADDED",
  "MEDIA:COMMENT",
  "EDITGROUP:ADDED",
  "MEDIA:MOVEDTO:MODERATE",
  "MEDIA:MOVEDTO:SHARED",
  "MEDIA:NEW:MODERATE",
  "EDITGROUP:NEW:COMMENT",
  "MEDIA:NEW:ASSETS",
  "MEDIA:BULK:DOWNLOAD",
  "EVENT:NEW:COMMENT",
  "MEDIA:NEW:ANNOTATION",
  "EDITGROUP:STATUS",
  "DEPARTMENT:NEW:COMMENT"
];


export default class NotificationList extends Component {

  state = {
    offset: 0,
    hasMore: true,
  };


  fetchMoreData = async () => {
    // if (this.props.events.rows.length >= this.props.activities.count) {
    if (this.props.notifications.length >= this.props.notificationCount) {
        await this.setState({ hasMore: false });
      return;
    }
    await this.setState((prevState, props) => ({
      // limit: prevState.limit + 10,
      offset: prevState.offset + 10
    }));
    await this.props.listNotifications({ offset: this.state.offset, limit: 10 });
    return;
  };




  render() {
    const { loading, hasMore, notifications, onClick, readAll } = this.props;

    // if (loading) return <Spin size="small" />;
    if (!notifications.length)
    return (
      <Menu>
        <Menu.Item onItemHover={console.log}>
          <List.Item.Meta className="no-notification-item" title={"No new Notifications"} />
        </Menu.Item>
      </Menu>
    );

  return (
    <div className="notification-wrapper">
      <Menu id="scrollableDiv2" style={{ height: 300, overflow:"auto" }} className="notification-container">
        <Menu.Item>
          <h2 className="header-title">Notifications</h2>
          <button className="btn clear-all-btn" onClick={e => readAll()}>Read all</button>
        </Menu.Item>
        {/* id="scrollableDiv" style={{ height: 300, overflow:"auto" }} */}
        <InfiniteScroll
            dataLength={notifications.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            scrollableTarget="scrollableDiv2"
            loader={ <div></div >}
            endMessage={
              <div className="loading-completed"></div>
            }
          >
        {notifications.map((item, i) => (
          <NotificationItem key={i} notification={item} onClick={onClick} />
        ))}
        </InfiniteScroll>
        {loading &&
          hasMore && (
            <div className="demo-loading-container">
              <Spin />
            </div>
          )}
      </Menu>
    </div>
  );

  }
}


function NotificationItem({ notification, onClick }) {
    const menuItemProps = {
      onItemHover: console.log,
      key: notification.id,
      onClick: console.log
    };
    const listItemMetaProps = {
      description: notification.body
    };
    switch (notification.type) {
      case notificationTypes[0]:
        listItemMetaProps.description = (
          <a href={notification.data.url} target="_blank" rel="noopener noreferrer">
            <Icon type="message" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </a>
        );
        break;
      case notificationTypes[1]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}`}>
            <Icon type="message" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[2]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/users`}>
            <Icon type="message" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[3]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[4]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/groups/${notification.data.group_id}`}>
            <Icon type="folder-open" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[5]:
      case notificationTypes[6]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[8]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/groups/${notification.data.group_id}`} >
            <Icon type="folder-open" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
      case notificationTypes[7]:
      case notificationTypes[9]:
        if (notification.data.departmentId) {
          listItemMetaProps.description = (
            <Link to={`/department/${notification.data.departmentId}/media/${notification.data.media_id}`}>
              <Icon type="video-camera" theme="filled" className="notification-icon-block" />
              <div className="notification-content-wrapper">
                <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
                <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
              </div>
            </Link>
          );
        } else {
          listItemMetaProps.description = (
            <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
              <Icon type="video-camera" theme="filled" className="notification-icon-block" />
              <div className="notification-content-wrapper">
                <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
                <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
              </div>
            </Link>
          );
        }
  
        break;
  
      case notificationTypes[10]:
        listItemMetaProps.description = (
          <a href={notification.data.url}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </a>
        );
        break;
  
      case notificationTypes[11]:
        listItemMetaProps.description = (
          <a href={`/events/${notification.data.event_id}?chat=open`}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </a>
        );
        break;
  
      case notificationTypes[12]:
        listItemMetaProps.description = (
          <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
  
      case notificationTypes[13]:
        listItemMetaProps.description = (
          <Link to={notification.data.media_id ? `/events/${notification.data.event_id}/media/${notification.data.media_id}` : `/events/${notification.data.event_id}/groups/${notification.data.group_id}`}>
            <Icon type="video-camera" theme="filled" className="notification-icon-block" />
            <div className="notification-content-wrapper">
              <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
              <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
            </div>
          </Link>
        );
        break;
  
        case notificationTypes[14]:
          listItemMetaProps.description = (
            <a href={`/department/${notification.data.department_id}?chat=open`}>
              <Icon type="video-camera" theme="filled" className="notification-icon-block" />
              <div className="notification-content-wrapper">
                <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
                <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
              </div>
            </a>
          );
          break;
  
  
      default:
    }
  
    return (
      <Menu.Item {...menuItemProps}>
        <List.Item actions={[<Tooltip title="Read"><button className="btn read-btn" onClick={e => onClick(notification)}></button></Tooltip>]}>
          <List.Item.Meta {...listItemMetaProps} />
          {/* <Icon type="close" theme="outlined" onClick={e => onClick(notification)} /> */}
        </List.Item>
      </Menu.Item>
    );
  }