import service from "./service";

export default {
  state: {
    loading: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    onAddCommentSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
  },
  effects: {
    async update(payload, rootState, id) {
      await service.update(payload, id);
    },
    async payOnInvoice(payload) {
      let res = await service.payOnInvoice(payload);
      this.onSuccess(res);
      return res;
    },
    async transferEventOwnerShip(payload) {
      let res = await service.transferEventOwnerShip(payload);
      this.onSuccess(res);
      return res;
    },
    async pusherIsTyping(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.pusherIsTyping(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async addEventsComment(payload) {
      try {
        this.onRequest();
        let res = await service.addEventsComment(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
    async addDepartmentComment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.addComment(payload)
        this.onAddCommentSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async addDepartmentMediaComment(payload, rootState) {
      try {
        this.onRequest()
        let res = await service.addMediaComment(payload)
        this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
        throw e
      }
    },
    async sendComment(payload) {
      try {
        this.onRequest();
        let res = await service.sendComment(payload);
        this.onSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        throw e;
      }
    },
  }
};
