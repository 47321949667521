import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import server from "../../config/server";
import { firebaseMessaging } from "../../config/firebase";
import { Dropdown, Icon, Badge } from "antd";
import NotificationList from "./NotificationList";


export const notificationTypes = [
  "MEDIA:SHARE",
  "EVENTUSER:INVITE",
  "EVENTUSER:ADDED",
  "MEDIA:COMMENT",
  "EDITGROUP:ADDED",
  "MEDIA:MOVEDTO:MODERATE",
  "MEDIA:MOVEDTO:SHARED",
  "MEDIA:NEW:MODERATE",
  "EDITGROUP:NEW:COMMENT",
  "MEDIA:NEW:ASSETS",
  "MEDIA:BULK:DOWNLOAD",
  "EVENT:NEW:COMMENT",
  "MEDIA:NEW:ANNOTATION",
  "EDITGROUP:STATUS",
  "DEPARTMENT:NEW:COMMENT"
];

const mapDispatchToProps = ({ notificationModel }) => {
  return {
    ...notificationModel
  };
};

const mapStateToProps = ({ notificationModel }) => {
  return {
    ...notificationModel
  };
};

class Notifications extends Component {

  state = {
    offset: 0,
    hasMore: true,
  };

  componentDidMount() {
    this.props.listNotifications({ offset:0, limit:10 });
    if (server.AllowPushNotification) {
      if (firebaseMessaging())
        this.unregisterNotification = firebaseMessaging().onMessage(payload => {
          const data = JSON.parse(payload.data.data);
          return this.props.addNotification(data).then(this.props.listNotifications);
        });
    }
  }

  fetchMoreData = async () => {
    // if (this.props.events.rows.length >= this.props.activities.count) {
    if (this.props.notifications.length >= this.props.notificationCount) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState((prevState, props) => ({
      // limit: prevState.limit + 10,
      offset: prevState.offset + 10
    }));
    await this.props.listNotifications({ offset: this.state.offset, limit: 10 });
    return;
  };

  async listNotifications (payload) {
    await this.props.listNotifications(payload);

  }

  componentWillUnmount() {
    this.unregisterNotification && this.unregisterNotification();
  }
  async update(notification) {
    this.props.handleReadNotification(notification.id);
    await  this.props.updateNotifications({id: notification.id,read: "1"})
    await this.props.listNotifications({ offset: this.state.offset, limit: 10 });

      // .then(this.props.listNotifications);
  }
  readAll() {
    return this.props.readAllNotifications().then(this.props.listNotifications);
  }

  render() {
    const { loading, notifications, notificationCount } = this.props;

    // if (loading) return <Spin size="small" />;
    return (
      <div >
      <Dropdown
        overlayClassName="notification-dropdown-pop"
        overlay={
            <NotificationList
              loading={loading}
              hasMore={true}
              notifications={notifications}
              notificationCount={notificationCount}
              onClick={this.update.bind(this)}
              readAll={this.readAll.bind(this)}
              listNotifications={this.listNotifications.bind(this)}
            />
        }
        trigger={["click"]}
      >
        <Badge count={notificationCount}>
          <Icon type="bell" className="notification-icon" theme="outlined" />
        </Badge>
      </Dropdown>
      </div>
    );
  }
}

// function NotificationList({
//   loading,
//   hasMore,
//   notifications,
//   notificationCount,
//   onClick,
//   readAll
// }) {
//   if (!notifications.length)
//     return (
//       <Menu>
//         <Menu.Item onItemHover={console.log}>
//           <List.Item.Meta className="no-notification-item" title={"No new Notifications"} />
//         </Menu.Item>
//       </Menu>
//     );

//   return (
//     <div className="notification-wrapper">
//       <Menu className="notification-container">
//         <Menu.Item>
//           <h2 className="header-title">Notifications</h2>
//           <button className="btn clear-all-btn" onClick={e => readAll()}>Read all</button>
//         </Menu.Item>
//         {notifications.map((item, i) => (

//           <NotificationItem key={i} notification={item} onClick={onClick} />
//         ))}
//         {loading &&
//           hasMore && (
//             <div className="demo-loading-container">
//               <Spin />
//             </div>
//           )}
//       </Menu>
//     </div>
//   );
//   // return (
//   //   <List
//   //     dataSource={notifications}
//   //     renderItem={item => (
//   //       <NotificationItem notification={item} onClick={onClick} />
//   //     )}
//   //   >
//   //     {loading &&
//   //       hasMore && (
//   //         <Menu.Item>
//   //           <Spin />
//   //         </Menu.Item>
//   //       )}
//   //   </List>
//   // );
// }

// function NotificationItem({ notification, onClick }) {
//   const menuItemProps = {
//     onItemHover: console.log,
//     key: notification.id,
//     onClick: console.log
//   };
//   const listItemMetaProps = {
//     description: notification.body
//   };
//   switch (notification.type) {
//     case notificationTypes[0]:
//       listItemMetaProps.description = (
//         <a href={notification.data.url} target="_blank" rel="noopener noreferrer">
//           <Icon type="message" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </a>
//       );
//       break;
//     case notificationTypes[1]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}`}>
//           <Icon type="message" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[2]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/users`}>
//           <Icon type="message" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[3]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[4]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/groups/${notification.data.group_id}`}>
//           <Icon type="folder-open" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[5]:
//     case notificationTypes[6]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[8]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/groups/${notification.data.group_id}`} >
//           <Icon type="folder-open" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;
//     case notificationTypes[7]:
//     case notificationTypes[9]:
//       if (notification.data.departmentId) {
//         listItemMetaProps.description = (
//           <Link to={`/department/${notification.data.departmentId}/media/${notification.data.media_id}`}>
//             <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//             <div className="notification-content-wrapper">
//               <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//               <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//             </div>
//           </Link>
//         );
//       } else {
//         listItemMetaProps.description = (
//           <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
//             <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//             <div className="notification-content-wrapper">
//               <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//               <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//             </div>
//           </Link>
//         );
//       }

//       break;

//     case notificationTypes[10]:
//       listItemMetaProps.description = (
//         <a href={notification.data.url}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </a>
//       );
//       break;

//     case notificationTypes[11]:
//       listItemMetaProps.description = (
//         <a href={`/events/${notification.data.event_id}?chat=open`}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </a>
//       );
//       break;

//     case notificationTypes[12]:
//       listItemMetaProps.description = (
//         <Link to={`/events/${notification.data.event_id}/media/${notification.data.media_id}`}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;

//     case notificationTypes[13]:
//       listItemMetaProps.description = (
//         <Link to={notification.data.media_id ? `/events/${notification.data.event_id}/media/${notification.data.media_id}` : `/events/${notification.data.event_id}/groups/${notification.data.group_id}`}>
//           <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//           <div className="notification-content-wrapper">
//             <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//             <p className="time-stamp">{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//           </div>
//         </Link>
//       );
//       break;

//       case notificationTypes[14]:
//         listItemMetaProps.description = (
//           <a href={`/department/${notification.data.department_id}?chat=open`}>
//             <Icon type="video-camera" theme="filled" className="notification-icon-block" />
//             <div className="notification-content-wrapper">
//               <p className="notification-content-block" dangerouslySetInnerHTML={{ __html: notification.html ? notification.html : notification.body }}></p>
//               <p >{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</p>
//             </div>
//           </a>
//         );
//         break;


//     default:
//   }

//   return (
//     <Menu.Item {...menuItemProps}>
//       <List.Item actions={[<Tooltip title="Read"><button className="btn read-btn" onClick={e => onClick(notification)}></button></Tooltip>]}>
//         <List.Item.Meta {...listItemMetaProps} />
//         {/* <Icon type="close" theme="outlined" onClick={e => onClick(notification)} /> */}
//       </List.Item>
//     </Menu.Item>
//   );
// }

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Notifications);
