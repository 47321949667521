const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

let isSafari;
try {
  isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
      (typeof window.safari !== 'undefined' && window.safari.pushNotification)
    );
} catch (e) {
  // console.log(e);
}

let all = {
  URL: 'https://api.binumipro.com',

  MEDIA_URL: 'https://s3.eu-west-2.amazonaws.com/iamego/public/',
  STRIPE_API_KEY: 'pk_test_zMKG7OGWBPq7KHYCkTg3c6QG007WVUW9bv',
  CREATE_EVENT: '/api/events',
  CREATE_EVENT_ASSETS: '/api/events/assets',
  DELETE_EVENT: '/api/events',
  EVENT_DETAILS: '/api/events/',
  LIST_ALL_EVENT: '/api/events',

  API_EVENT: '/api/events',
  API_CONTACT_US: '/api/contact-us',
  API_REGISTRATION_REQUESTS: '/api/registration-requests',
  API_EVENT_USERS: '/api/event-users',
  API_EVENT_USERS_INVITE: '/api/event-users/invite',
  API_NOTIFICATION_TOKENS: '/api/notification-tokens',

  ADD_MEDIA: '/api/media',
  SIGN_UP: '/api/login/signup',
  SEARCH: '/api/user/search',
  ADD_USER: '/api/eventusers',
  DELETE_MEDIA: '/api/media/delete',
  GET_COMMENTS: '/api/media/mediacomments?media_id=',
  UPLOAD_MEDIA: '/file/upload',
  UPDATE_MEDIA: '/api/media/update',
  MEDIA_SOCIAL_SHARE: '/api/media/social',
  MEDIA_DOWNLOAD: '/media-download',
  MEDIA_DOWNLOAD_STATUS: '/media-download-status',

  ME: "/api/users/me",
  NOTIFICATIONS: "/api/notifications",
  AUTH: "/api/auth/",
  CHECK_AUTH: "/api/check-token",
  REMOVE_TOKEN: "/api/remove-token",
  API_SHARED_LINKS: "/api/shared",
  API_UNDOWNLOADED: "/api/shared/undownloaded/media",
  API_UNREAD_COMMENTS: "/api/shared/unread/comments",
  API_UNREAD_EVENT_COMMENTS: "/api/shared/unread/event-comments",
  API_UNDOWNLOADED_EVENT_MEDIA: "/api/shared/undownloaded/events-media",
  API_CREATE_LOGS: "/api/activities",
  API_ALL_COMMANDS: "/api/activities/all-commands",
  API_ACTIVE_USERS: "/api/activities/active-users",
  API_ACTIVITY_DATES: "/api/activities/activity-dates",

  API_CREATE_NOTES: "/api/notes",
  API_GET_URL: "/api/s3/sign-url",
  API_USERS: "/api/users",
  API_USERS_TRANSACTION: "/api/transaction/stripe",
  API_LIST_CARDS: "/api/transaction/list-cards",
  API_ADD_CARDS: "/api/transaction/add-cards",
  API_DELETE_CARD: "/api/transaction/delete-cards",
  API_SET_CARDS: "/api/transaction/default-cards",
  PAY_ON_INVOICE: "/api/transaction/pay-on-invoice",

  API_ADD_USER_LICENCE: '/api/licence',
  API_SEND_CREDITS: '/api/licence/send-credits',
  API_CHANGE_OWNER: '/api/licence/change-owner',
  API_CUSTOM_VALUE: '/api/licence/custom-value',

  API_ADD_LICENCE_DETAILS: '/api/licence/add-details',
  API_GET_LICENCE_DETAILS: '/api/licence/details',
  API_GET_ALL_ORGS: '/api/licence/all-organizations',
  API_GET_ALL_OWNERS: '/api/licence/all-subscription-Owners',
  API_GET_CREDITS: '/api/licence/get-credits',
  API_ADD_USER: '/api/licence/add-user',
  API_REMOVE_LICENCE_DETAILS: '/api/licence/remove-organisation',
  API_GET_COMPANIES: '/api/company',

  API_DEPARTMENTS: 'api/department',

  USER_MEDIA_UPLOAD: '/api/videos',
  USER_MEDIA_EXISTS: '/api/videos/exist',

  AllowPushNotification:
    (window.location.protocol === 'https:' || isLocalhost) && !isSafari
};

all.apiGroups = eventId => {
  return `${all.API_EVENT}/${eventId}/groups`;
};

all.apiTransferOwner = eventId => {
  return `${all.API_EVENT}/${eventId}/transfer-ownership`;
};

all.apiGroupMedia = ({ eventId, groupId }) => {
  return `${all.API_EVENT}/${eventId}/groups/${groupId}/media`;
};

all.apiGroupComments = ({ eventId, groupId }) => {
  return `${all.API_EVENT}/${eventId}/groups/${groupId}/comments`;
};

all.removeGroupMedias = (eventId, groupId) => {
  return `${all.API_EVENT}/${eventId}/groups/${groupId}/media`;
};

all.apiMedia = (eventId, fileStatus) => {
  let apiUrl = `${all.API_EVENT}/${eventId}/media`;
  if (fileStatus || fileStatus === 0) {
    apiUrl += `?file_status=${fileStatus}`;
  }
  return apiUrl;
};

all.apiMediaExist = (eventId, fileStatus) => {
  let apiUrl = `${all.API_EVENT}/${eventId}/media/exist`;
  return apiUrl;
};

all.apiMediaUpdate = (eventId, fileStatus) => {
  let apiUrl = `${all.API_EVENT}/${eventId}/media/replace`;
  if (fileStatus || fileStatus === 0) {
    apiUrl += `?file_status=${fileStatus}`;
  }
  return apiUrl;
};

all.apiThumbnailUpdate = eventId => {
  let apiUrl = `${all.API_EVENT}/${eventId}/media/replace-thumbnail`;
  return apiUrl;
};

all.apiEventUsers = eventId => {
  return `${all.API_EVENT}/${eventId}/users`;
};

all.apiMediaComments = (eventId, mediaId) => {
  return `${all.API_EVENT}/${eventId}/media/${mediaId}/comments`;
};

all.apiEventComments = eventId => {
  return `${all.API_EVENT}/${eventId}/comments`;
};

all.twilioToken = (eventId, mediaId) => {
  return `api/twilio/token`;
};

all.apiMediaShareUrl = (eventId, mediaId) => {
  return `${all.API_EVENT}/${eventId}/media/${mediaId}/share`;
};

all.apiGroupStatus = (eventId, groupId) => {
  return `${all.API_EVENT}/${eventId}/groups/${groupId}/status`;
};

all.apiSharedMedia = mediaId => {
  return `/api/shared/${mediaId}`;
};

all.apiUserImage = userId => {
  return `/api/users/${userId}/upload`;
};

let env = {
  development: {
    // URL: "http://192.168.2.18:3000",
    URL: "https://api.binumipro.com",
    // URL: 'https://stagingapi.binumipro.com',
    ENVT: 'development',
    pusherKey: '01e650e64d81b36dce26',
    pusher: {
      cluster: 'eu',
      encrypted: true
    },
    s3: {
      region: 'eu-west-2',
      accessKeyId: 'AKIAJRW3YC37L3T72HRA',
      secretAccessKey: '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',
      params: {
        bucket: 'iamego-staging',
        userAvatarBucket: 'iamego'
      }
    },
    s3BaseUrl: 'https://iamego-staging.s3.eu-west-2.amazonaws.com/',
    cloudfrontUrl: 'https://d120694hjqgob0.cloudfront.net/',
    MEDIA_NEW_URL: 'https://s3.eu-west-2.amazonaws.com/iamego-staging/'
  },
  staging: {
    URL: 'https://stagingapi.binumipro.com',
    ENVT: 'staging',
    pusherKey: 'd7ba029b9c6eda1534a8',
    pusher: {
      cluster: 'eu',
      encrypted: true
    },
    s3: {
      region: 'eu-west-2',
      accessKeyId: 'AKIAJRW3YC37L3T72HRA',
      secretAccessKey: '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',
      params: {
        bucket: 'iamego-staging',
        userAvatarBucket: 'iamego'
      }
    },
    s3BaseUrl: 'https://iamego-staging.s3.eu-west-2.amazonaws.com/',
    cloudfrontUrl: 'https://d120694hjqgob0.cloudfront.net/',
    MEDIA_NEW_URL: 'https://s3.eu-west-2.amazonaws.com/iamego-staging/'
  },
  production: {
    URL: "https://api.binumipro.com",
    //TEMP TEST - REACTIVE THROUGH DNS WHEN SWITCHING
    // URL: 'https://prod.binumipro.com',
    ENVT: 'production',
    pusherKey: '2858263d5f374da6ea1a',
    pusher: {
      cluster: 'eu',
      encrypted: true
    },
    bucket: 'iamego'
  }
};

const x = process.env.REACT_APP_ENV || 'development';

const config = {
  ...all,
  ...env[x]
};

// console.log("env : ", process.env.REACT_APP_ENV);
// console.log("server : ", config.URL);

export default config;
