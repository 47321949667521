import * as service from "./service";

export default {
  state: {
    loading: false,
    notifications: []
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      };
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state) {
      return {
        ...state,
        loading: false
      };
    },
    clearNotification(state, data) {
      return {
        ...state,
        notifications: [],
        notificationCount: 0

      };
    },
    onListNotificationsSuccess(state, data) {
      let oldNotification = state.notifications || []
      let newNotification = oldNotification.concat(data.rows || [])
      return {
        ...state,
        loading: false,
        notifications: newNotification,
        notificationCount: data.count

      };
    },
    addNotification(state, data) {
      const notifications = [].concat(state.notifications);
      notifications.unshift(data);
      return {
        ...state,
        notifications: notifications
      };
    },
    handleReadNotification(state,id){
      let data = state.notifications.filter(el => el.id !== id );
      return {
        ...state,
        notifications: data
      };
    }
  },
  effects: {
    async listNotifications(payload, rootState) {
      try {
        if(payload.offset === 0) this.clearNotification();
        
        this.onRequest();
        let res = await service.list(payload);
        this.onListNotificationsSuccess(res);
      } catch (e) {
        this.onError(e);
      }
    },
    async updateNotifications(payload, rootState) {
      try {
        // let res = await service.update(payload);
         await service.update(payload);
      } catch (e) {
        this.onError(e);
      }
    },
    async readAllNotifications(payload, rootState) {
      try {
        this.onRequest();
        let res = await service.readAll(payload);
        this.onSuccess(res);
      } catch (e) {
        this.onError(e);
      }
    }
  }
};
