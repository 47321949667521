import { api, catchHandler } from "../../helpers/axios";
import server from "../../config/server";
import { notification } from "antd";


export async function deleteLicencedEditors(data) {
  return (await api())
    .delete(`api/remove-editor?eventid=${data.event_id}&userId=${data.user_id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function deleteEventEditor(data) {
  return (await api())
    .delete(`api/events/${data.event_id}/users/${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getEditorsEventsList(data) {
  return (await api())
    .get(`api/editors-events-list?userId=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getAllEditors(data) {
  let url
  if (data)
    url = `api/all-editors?search=${data}`
  else
    url = `api/all-editors`

  return (await api())
    .get(url)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function checkUser(data) {
  return (await api())
    .post(`api/licence/check-user`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function deleteCard(data) {
  return (await api())
    .post(`${server.API_DELETE_CARD}`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function editCredits(data) {
  return (await api())
    .post(`api/licence/edit-credits`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function removeEventCredits(data) {
  return (await api())
    .post(`api/events/${data.event_id}/transactions/remove-event-credits`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function addEventEditors(data) {
  return (await api())
    .post(`api/add-editors`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getEditorDetails(data) {
  return (await api())
    .get(`api/user-editors?eventId=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}


export async function getAvailableEditorsDetails(data) {
  return (await api())
    .get(`api/editors-available?eventId=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function updateLicenceDetails(data) {
  return (await api())
    .put(`${server.API_GET_LICENCE_DETAILS}`, data)
    .then(res => {

      if (res.data && res.data.status === false) {
        notification.error({
          message: res.data.message,
          description: res.data.message
        });
      }
      if (res.data && res.data.status === true) {
        notification.success({
          message: "Submitted successfully",
          description: "Submitted successfully"
        });
      }


      return res.data
    })
    .catch(catchHandler);
}

export async function getUsersDetails(data) {
  return (await api())
    .get(`${server.API_GET_LICENCE_DETAILS}?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}
// export async function getActivityDates() {
//   return (await api())
//     .get(`${server.API_ACTIVITY_DATES}`)
//     .then(res => res.data)
//     .catch(catchHandler);
// }
export async function getAllOwners(data) {
  return (await api())
    .get(`${server.API_GET_ALL_OWNERS}?search=${data}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getAllOrganizations(data) {
  return (await api())
    .get(`${server.API_GET_ALL_ORGS}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getCredits(data) {
  return (await api())
    .get(`${server.API_GET_CREDITS}?id=${data}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getCards(data) {
  return (await api())
    .get(`${server.API_LIST_CARDS}?userId=${data}`)
    .then(res => res.data)
    .catch(catchHandler);
}
export async function sendTransaction(data, userId) {
  return (await api())
    .post(`${server.API_USERS_TRANSACTION}`, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export async function sendCredits(data) {
  return (await api())
    .post(server.API_SEND_CREDITS, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function addCard(data) {
  return (await api())
    .post(server.API_ADD_CARDS, data)
    .then(res => {
      return res.data
    })
    .catch(catchHandler);
}

export async function setDefaultCard(data) {
  return (await api())
    .post(server.API_SET_CARDS, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function payOnInvoice(data) {
  return (await api())
    .post(server.PAY_ON_INVOICE, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function customValue(data) {
  return (await api())
    .post(server.API_CUSTOM_VALUE, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function transferOwner(data) {
  return (await api())
    .post(server.API_CHANGE_OWNER, data)
    .then(res => res.data)
    .catch(catchHandler);
}
export async function addLicenceDetails(data) {
  return (await api())
    .post(server.API_ADD_LICENCE_DETAILS, data)
    .then(res => {
      if (res.data && res.data.status === false) {
        notification.error({
          message: res.data.message,
          description: res.data.message
        });
      }
      if (res.data && res.data.status === true) {
        notification.success({
          message: "Submitted successfully",
          description: "Submitted successfully"
        });
      }
      return res.data
    })
    .catch(catchHandler);
}

// export async function getUsers({ limit, offset, value, ...rest }) {
//   console.log("server.API_USERS", server.API_USERS)
//   let apiUrl
//   if (value) {
//     console.log("IF", server.API_USERS)
//     apiUrl = `${server.API_USERS}?limit=${limit}&offset=${offset}&value=${value}`
//   } else {
//     console.log("ELSE", server.API_USERS)
//     apiUrl = `${server.API_USERS}?limit=${limit}&offset=${offset}`
//   }
//   return (await api())
//     .get(apiUrl)
//     .then(res => res.data)
//     .catch(catchHandler);
// }

export async function updateUserDetails(data) {
  return (await api())
    .put(`${server.API_USERS}/${data.userId}/update`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getAllUsers(data) {
  let apiUrl = `api/all-users?id=${data.id}`
  return (await api())
    .get(apiUrl)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getUserCompanies(data) {
  return (await api())
    .get(`${server.API_GET_COMPANIES}?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function onSubmitCompanyDetails(data) {
  return (await api())
    .post(server.API_GET_COMPANIES, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function addUser(data) {
  return (await api())
    .post(server.API_ADD_USER, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function deleteUser(data) {
  return (await api())
    .delete(server.API_ADD_USER_LICENCE + '/' + data.id)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getUnreadComments(data) {
  return (await api())
    .get(`${server.API_UNREAD_EVENT_COMMENTS}?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getUnDownloadedMedia(data) {
  return (await api())
    .get(`${server.API_UNDOWNLOADED_EVENT_MEDIA}?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function createChatCommand(data) {
  return (await api())
    .post(`api/operations/chat-commands`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function getAllChat(data) {
  let url
  if (data && data.search)
    url = `api/operations/chat-commands?search=${data.search}`
  else if (data && data.field)
    url = `api/operations/chat-commands?field=${data.field}&order=${data.order}`
  else
    url = `api/operations/chat-commands`
  return (await api())
    .get(url)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function onDeleteCommand(data) {
  return (await api())
    .delete(`api/operations/chat-commands?id=${data.id}`)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function updateChatCommand(data) {
  return (await api())
    .put(`api/operations/chat-commands`, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function removeOrganisation(data) {
  return (await api())
    .post(server.API_REMOVE_LICENCE_DETAILS, data)
    .then(res => res.data)
    .catch(catchHandler);
}

export async function createTransaction(data) {
  return (await api())
    .post(server.API_CREATE_TRANSACTION, data)
    .then(res => {
      return res.data
    })
    .catch(catchHandler);
}